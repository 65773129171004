

.inflencers_Search {
  width: 55%;
  margin: 0% auto;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: end;
}

.filtersBtn_Cont {
  position: relative;
}
.countryOptions {
  width: 20%;
  position: fixed;
  z-index: 100;
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
}

.clear_Save_Cont {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}

.clear {
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  color: var(--graySmooth);
  text-decoration: underline;
  cursor: pointer;
}

.save {
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  background-color: var(--greenColor);
  font-weight: 500;
  padding: 10px;
  color: var(--whiteColor);
  border-radius: 10px;
  cursor: pointer;
}

.filtersBtn {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 12px 8px 12px;
  border: 1px solid var(--grayColor);
  box-shadow: var(--boxShadow);
  border-radius: 30px;
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  cursor: pointer;
}

.downArrow {
  color: var(--blackColor);
  /* font-weight: 600; */
  font-size: var(--fontSize16);
  margin-left: 5px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:111;
}

.modal-content {
  /* width: 32%; */
  width: 50%;
  height: fit-content;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: var(--boxShadow);
}

.modalHeading_Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalHeading {
    font-size: var(--fontSize18);
    font-family: var(--sansSarif);
    font-weight: 500;
}

.modalClose {
  text-align: end;
  color: var(--blackColor);
  /* font-weight: 600; */
  font-size: var(--fontSize26);
  font-family: var(--sansSarif);
  transform: rotate(45deg);
  cursor: pointer;
}

.label_filteres_Modal {
    font-size: var(--fontSizeSmall);
    font-family: var(--sansSarif);
    color: var(--grayColor);
    margin-top: 10px;
    font-weight: 500;
}

.modal-content > select {
    margin-top: 6px;
}

.influencers_card {
  padding: 0 6% 0px 6%;
  margin-top: 50px;
  margin-bottom: 30px;
}

.clear_All> button {
  padding: 8px 12px 8px 12px;
  border: 1px solid var(--grayColor);
  box-shadow: var(--boxShadow);
  border-radius: 30px;
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  color: var(--whiteColor);
  background-color: var(--blackColor);
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .inflencers_Search {
    width: 90%;
    gap: 10px;
    justify-content: center;
  }
  .filtersBtn {
    padding: 4px 10px 4px 10px;
  }
  .clear_All> button  {
    padding: 4px 10px 4px 10px;
  }
  .countryOptions {
    width: 60%;
  }
  .modal-content {
    width: 80%;
  }
}
