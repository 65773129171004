.tabText {
    color: var(--white) !important;
    font-size: var(--fontSizeSmall) !important;
}

.brand_page {
    margin: 2% 26% 2% 26%;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.brand_card {
    margin: 0 auto;
    width: 96%;
    font-family: var(--monrope);
    display: flex;
    margin-top: 15px;
}

.brand_Request_offer_status {
    background-color: var(--blackColor);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);
}

.brand_Request_offer_status_approved {
    background-color: var(--greenColor);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);

}

.brand_Request_offer_status_denied {
    background-color: rgb(255, 142, 142);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);

}


.brand_image_section {
    width: 15%;
    border-radius: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand_content_section {
    width: 65%;
    padding: 0 20px;

}

.brand_btn_section {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: center;
    margin-top: 10px;
}

.brand_image_section>img {
    width: 100%;
    border-radius: 15px;
    /* height: 315px; */
}

.brand_content_section>p {
    text-align: justify;
}

.brand_btn_section>div {
    display: block;
    text-align: center;
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 8px;
    box-shadow: var(--grayColor);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    padding: 14px;
    /* width: 150px; */
    width: 70%;
    margin: 15px auto;
    color: white;
}

.view_profile_btn {
    background-color: var(--blackColor);
}

.accept_btn {
    background-color: var(--greenColor);
}

.decline_btn {
    background-color: rgb(255, 142, 142);
}

.not_exists{
    font-family: var(--monrope);
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--grayColor);

}
.not_exists > svg{
    margin-bottom: 5px;
}

@media screen and (max-width: 920px) {
    .brand_page {
        /* margin: 0 7%; */
        margin: 3% 7% 0 7%;

    }

    .brand_card {
        display: block;
    }

    .brand_image_section {
        width: auto;
    }

    .brand_content_section>div {
        display: flex;
        justify-content: space-between;
    }

    .brand_content_section>div>.price_content {
        display: flex;
        order: 2;
    }

    .brand_content_section>div>h2 {
        margin-bottom: 1px;
        font-size: 16px;
    }

    .brand_content_section>p {
        font-size: 12px;
    }

    .brand_content_section {
        width: 90%;

    }

    .brand_btn_section {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .brand_btn_section>div {
        margin: 8px 11px;
        font-size: 74%;
        padding: 14px 1px;
    }

    .brand_image_section>img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        /* height: 315px; */
    }
}

@media screen and (max-width: 350px) {
    .brand_btn_section>div {
        margin: 8px 3px;

    }
}

@media screen and (max-width: 650px) {
    .tabText {
        font-size: 12px !important;
    }
}