.influencers {
  padding: 0 6% 0px 6%;
  /* margin-top: 70px; */
  margin-top: 20px;
}

.influencers_heading {
  font-size: var(--headingFontSmall);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 600;
}

.influencers_heading_desc {
  display: flex;
  justify-content: space-between;
}

.influencers_heading_desc_One {
  color: var(--grayColor);
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  margin-top: 5px;
}

.influencers_seeAll {
  color: var(--blackColor);
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  margin-top: 5px;
  font-weight: 700;
}




.influencers_listing-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.influencers_featured-profile-listing-holder {
  width: 50%;
  /* min-width: 230px; */
}

.influencers_link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.influencers_profile-listing-img-holder {
  width: 100%;
  height: 310px;
  position: relative;
  border-radius: 9px;
  background-color: black;
  overflow: hidden;
}

.influencers_profile-listing-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 8px;
  -webkit-mask-image: linear-gradient(to top,
      rgb(0 0 0 / 50%) 10%,
      rgba(0, 0, 0, 1));
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.13, 1.02) 0s;
}

.influencers_profile-listing-img:hover {
  transform: scale(1.1);
}

.influencers_profile-listing-owner-holder {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
}

.influencers_profile-listing-owner-info-holder {
  display: flex;
  flex-direction: column;
}

.influencers_profile-listing-owner-name {
  display: flex;
  align-items: center;
  font-size: 80%;
  color: white;
  font-weight: 600;
  font-family: var(--sansSarif);
}

.influencers_verified-badge {
  width: 14px;
  margin-left: 4px;
}

.influencers_profile-listing-owner-location {
  font-size: 72%;
  color: white;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin-top: 1px;
}

.influencers_profile-listing-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0;
}

.influencers_profile-listing-category {
  color: var(--lightGray);
  font-size: 75%;
  font-family: var(--sansSarif);
}

.influencers_profile-listing-price {
  font-weight: 600;
  font-size: 95%;
  font-family: var(--sansSarif);
}

.influencers_profile-listing-title {
  font-size: 90%;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin: 0;
}

.influencers_card_platform_price {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.influencers_platform {
  color: var(--graySmooth);
  font-family: var(--sansSarif);
  font-size: var(--fontSizeSmall);
  font-weight: 500;
}

.influencers_price {
  color: var(--blackColor);
  font-family: var(--sansSarif);
  font-size: 16px;
  font-weight: 600;
}

.influencers_description {
  margin-top: 5px;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-smooth: always;
}

@media screen and (max-width: 1150px) {
  .influencers_listing-row {
    overflow-x: auto;
    gap: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .influencers_Card_Container {
    overflow-x: auto;
    /* overflow: hidden; */
    gap: 10px;
  }

  .influencers_Card_Container::-webkit-scrollbar {
    width: 0px;
  }

  .influencers_profile-listing-img-holder {
    height: 210px;
  }

  /* .influencers_featured-profile-listing-holder {
    width: 40%;
  }
 
  .influencers_Card_Cont {
    width: 100%;
  } */
  .influencers_Card {
    width: 100%;
    height: 210px;
  }
}


@media screen and (max-width: 900px) {
  .influencers_listing-row {
    overflow-x: auto;
    /* overflow: hidden; */
    gap: 10px;
  }

  .influencers_listing-row::-webkit-scrollbar {
    width: 0px;
  }

  .influencers_featured-profile-listing-holder {
    /* width: 160px; */
    width: 190px !important;
  }

  .influencers_profile-listing-img-holder {
    width: 100%;
    height: 210px;
  }

  .influencers_Card {
    width: 160px;
    height: 210px;
  }

  .influencers_Card_Cont {
    width: 160px;
  }

}

.influencers_featured-profile-listing-holder {
  width: 24%;
  min-width: 180px;
}

@media screen and (max-width: 550px) {

  .influencers_heading_desc_One {
    font-size: var(--fontSizeXSmall);
  }

  .influencers_seeAll {
    font-size: var(--fontSizeXSmall);
  }

  .influencers_listing-row {
    margin-top: 5%;
  }
  .influencers_profile-listing-img-holder {
    height: 180px;
}
}