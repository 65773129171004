
.collabsCafeFAQS {
    padding: 0 6% 0px 6%;
    margin-top: 50px;
}

.collabsCafeFAQS_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;
}

.collabsCafeFAQS_title{
    color: var(--blackColor);
    font-size: var(--fontSize16);
    font-family: var(--sansSarif);
    font-weight: 600;
    
}

.plusArrow {
    color: var(--grayColor);
    font-size: 25px;
    font-family: var(--sansSarif);
    font-weight: 500;
    
}

.clickesArrow {
    color: var(--grayColor);
    font-size: 25px;
    font-family: var(--sansSarif);
    font-weight: 500;
    transform: rotate(-43deg);
}

.accoudion_Desc {
    padding: 20px 0px 20px 0px;
    color: var(--grayColor);
}

.line_separator {
    height: 1px;
    background-color: var(--grayColor);
    margin: 10px 0px 10px 0px;
}