.campaignGuide {
    padding: 0 6% 0px 6%;
    margin-top: 70px;
  }
  
  .campaignGuide_Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* gap: 50px; */
  }
  
  .campaignGuide_desc {
    width: 40%;
  }
  .campaignGuide_Image {
    width: 55%;
  }
  
  .campaignGuide_heading {
    font-size: var(--headingFontMedium);
    font-family: var(--sansSarif);
    color: var(--greenColor);
    font-weight: 500;
  }
  
  .campaignGuide_Item {
    margin-top: 30px;
  }
  
  .campaignGuide_Item_Title {
    font-size: var(--fontSize22);
    font-family: var(--sansSarif);
    color: var(--greenColor);
    font-weight: 500;
  }
  
  .campaignGuide_Item_desc {
    margin-top: 10px;
    font-size: var(--fontSize15);
    font-family: var(--sansSarif);
    color: var(--grayColor);
    font-weight: 500;
  }
  
  .campaignGuide_Image > img {
    width: 100%;
  }
  
  @media screen and (max-width: 800px) {
    .campaignGuide_heading {
      font-size: var(--headingFontSmall);
      margin-top: 40px;
    }
    .campaignGuide_Item_Title {
      font-size: var(--fontSize18);
    }
    .campaignGuide_desc {
      width: 100%;
      margin-top: 10px;
    }
    .campaignGuide_Image {
      width: 100%;
      margin-top: 10px;
    }
  }
  