.footer {
  padding: 0 6% 0px 6%;
  margin-top: 50px;
}

.footer_Container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.copyRights {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  font-size: var(--fontSizeXSmall);
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}

.social_Links {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.insta_icon {
  color: #e1306c !important;
  font-size: 20px !important;
  cursor: pointer;
}
.tiktok_icon {
  color: #ff0050 !important;
  font-size: 20px !important;
  cursor: pointer;
}
.twitter_icon {
  color: #00acee !important;
  font-size: 20px !important;
  cursor: pointer;
}
.fb_icon {
  color: #4267b2 !important;
  font-size: 20px !important;
  cursor: pointer;
}


@media screen and (max-width: 800px) {
  .footer_Container {
    display: flex;
    flex-direction: column-reverse;
  }
}
