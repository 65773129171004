.main_brand_view{

    margin: 2% 16% 0 16%;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: var(--monrope);
}

.brandRequestStatus_Date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.brand_view_card{
    margin: 0 auto;
    width: 96%;
    font-family: var(--monrope);
    display: flex;
    margin-top: 15px;
}
.brand_view_first_section{
    width: 24%;
}

.offer_status {
    background-color: var(--blackColor);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);
}

.offer_status_approved {
    background-color: var(--greenColor);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);

}

.offer_status_denied {
    background-color: rgb(255, 142, 142);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    color: var(--white);
    font-size: var(--fontSizeXSmall);

}

.brand_view_createdAt> div{
    /* display: block; */
    /* text-align: center; */
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 4px;
    box-shadow: var(--grayColor);
    background: rgba(5, 160, 129, 0.2);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    /* position: relative; */
    padding: 8px;
    width: fit-content;
    
    color: var(--greenColor);
    border: 1px solid var(--greenColor);
}

.brand_view_first_section > img{
    /* width: 250px;
    height: 250px; */
    width: 86%;
}
.brand_view_first_section > div > h4{
    color: var(--blackColor) ;
}
.brand_view_second_section > p {
    width: 100%;
    word-break: break-all;
    color: var(--grayColor);

}
.brand_view_second_section{
    width: 76%;
    /* background: var(--greenColor); */
}
.brand_view_detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--grayColor);
    margin-bottom: 5px;
    width: 86%;
    flex-wrap: wrap;
}
.brand_view_detail > h2{
    color: black;
    margin: 0;
    margin-right: 5px;
}
.brand_view_detail > div{
    display: flex;
    align-items: center;
}
.brand_view_detail > span{
    margin: 0 5px;
}
.brand_designation{
    color: var(--greenColor);
    font-size: 16px;
}
.brand_price{
    color: var(--blackColor);
    margin-top: 5px;
    font-size: 16px;
}
.brand_btn_section_row{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 86%;
    flex-wrap: wrap;
}

.brand_btn_section_row>div {
    display: block;
    text-align: center;
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 0px;
    box-shadow: var(--grayColor);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    padding: 10px;
    width: 100px;
    margin: auto 10px auto 0;
    color: white;
    margin-top: 10px;

}
.brand_btn_section_row>div:hover{
    opacity: 0.8;
}
.brand_about_section{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.brand_about_section > h2{
    margin: 0;
    margin-left: 10px;
}
.brand_about_detail > hr{
    height: 1px;
    background-color: var(--graySmooth);
    border: none;
}
.brand_about_detail > div > p{
    color: var(--grayColor);
}
.brand_contact_info{
    display: flex;
    width: 100%;
    margin-top: 16px;
}
.brand_contact_info > div:first-child{
    width: 20%;
}
.brand_view_work{
    display: flex;
    width: 100%;
    align-items: end;
}
.brand_view_work > p {
    color: var(--grayColor);
}
.brand_view_work > hr{
    width: 100%;
    height: 1px;
    margin: 3px;
    background-color: var(--graySmooth);
    border: none;
}

.brand_view_primary > div{
    display: block;
    text-align: center;
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 4px;
    box-shadow: var(--grayColor);
    background: rgba(5, 160, 129, 0.2);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    padding: 5px;
    width: 85%;
    
    color: var(--greenColor);
    border: 1px solid var(--greenColor);
}
.brand_view_work_detail{
    display: flex;
    align-items: center;
    width: 86%;
}
.brand_view_work_detail > h4{
    margin: 15px 15px 10px 0px;

}

.brandRequest_View_SocialLinks {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}


@media screen and (max-width: 2925px) {
    .brand_btn_section_row>div{
        width: 100%;
    }
}


@media screen and (max-width: 1200px) {
    .brand_view_work_detail{
        display: flex;
        flex-direction: column;
        margin: 10px 0px;
        align-items: flex-start;
    }
}

@media screen and (max-width: 920px) {
    .main_brand_view{
        margin: 2% 6% 0 6%;
    }
.brand_view_card{
    display: flex;
    flex-direction: column;
}
.brand_view_first_section{
    width: 100%;
}
.brand_btn_section_row{
margin-bottom: 10px;
width: 100%;
}
.brand_btn_section_row > div{
    width: 100px;
}
.brand_view_second_section{
    width: 100%;

}
.brand_view_first_section > img{
    width: 30%;
}
}

@media screen and (max-width: 380px){
    .brand_view_detail{
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    }
    .brand_btn_section_row {
        display: flex;
        flex-direction: column;
        width: 91%;
    }
    .brand_btn_section_row>div{
        margin-top: 10px;
        width: 100%;
    }
}

