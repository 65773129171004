.stepFour {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.input_Container {
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
}

.radioBtn {
  padding: 10px;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
}

.label {
  margin-left: 20px;
}
.stepFour-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .stepFour {
    width: 90%;
  }
  .input_Container {
    display: flex;
    justify-content: left;
  }
  .stepFour-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
  .stepOne_label {
      font-size: 12px;
    }
}
