.stepEight {
  width: 70%;
  margin: 0% auto;
  margin-top: 25px;
  /* padding-bottom: 30px; */
}

.stepEight_Cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepEight-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.deletePackage {
  font-size: var(--fontSize26);
  cursor: pointer;
  /* font-size: var(--headingFontSmall); */
  transform: rotateZ(45deg);
  font-family: var(--sansSarif);
}

.stepEight-desc {
  margin-top: 10px;
  color: var(--grayColor);
  font-size: var(--fontSize15);
  font-weight: 500;
  font-family: var(--sansSarif);
}

.useCalculator {
  color: var(--blackColor);
  text-decoration: underline;
  font-size: var(--fontSize15);
  font-weight: 500;
  font-family: var(--sansSarif);
}

.packages_cards_Container {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}

.packages_cards {
  width: 280px;
  min-height: 370px;
  box-shadow: var(--boxShadow);
  border-left: 8px solid #bfc6ca;
  border-radius: 10px;
  margin-top: 15px;
}

.packages_cards_Items {
  padding: 15px;
}

.packages_heading {
  color: var(--blackColor);
  font-size: var(--fontSize18);
  font-weight: 600;
  font-family: var(--sansSarif);
}

.packages_inputSelect {
  width: 100%;
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  margin-top: 13px;
}
.packages_input {
  width: 97%;
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  font-family: var(--sansSarif);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  margin-top: 13px;
}

.packages_input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}
.package_description {
  width: 97%;
  border: 1px solid var(--borderColorTwo);
  font-family: var(--sansSarif);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  resize: vertical;
  margin-top: 13px;
}
.package_description::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}

.collabsFeesDeduction {
  font-size: var(--fontSize10);
  color: var(--graySmooth);
  font-weight: 500;
  font-family: var(--sansSarif);
  text-align: right;
  margin-top: 5px;
}
.price_error {
  font-size: var(--fontSize10);
  color: red;
  font-weight: 500;
  font-family: var(--sansSarif);
  /* text-align: right; */
  margin-top: 5px;
}

.addMoreButtonCont {
  display: flex;
  justify-content: end;
}

.addMoreButton {
  margin-top: 20px;
  background-color: #d6efe2;
  color: green;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  padding: 8px 12px 8px 12px;
  font-family: var(--sansSarif);
  cursor: pointer;
  border-radius: 25px;
}

@media screen and (max-width: 800px) {
  .stepEight {
    width: 90%;
  }
  .packages_cards {
    width: 100%;
  }
}
