.influencerGuide {
  padding: 0 6% 0px 6%;
  margin-top: 70px;
}

.influencerGuide_Container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  /* gap: 50px; */
}

.influencerGuide_desc {
  width: 40%;
}
.influencerGuide_Image {
  width: 55%;
}

.influencerGuide_heading {
  font-size: var(--headingFontMedium);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 500;
}

.influencerGuide_Item {
  margin-top: 30px;
}

.influencerGuide_Item_Title {
  font-size: var(--fontSize22);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 500;
}

.influencerGuide_Item_desc {
  margin-top: 10px;
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
}

.influencerGuide_Image > img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .influencerGuide_heading {
    font-size: var(--headingFontSmall);
    margin-top: 40px;
  }
  .influencerGuide_Item_Title {
    font-size: var(--fontSize18);
  }
  .influencerGuide_desc {
    width: 100%;
    margin-top: 10px;
  }
  .influencerGuide_Image {
    width: 100%;
    margin-top: 10px;
  }
}
