.line {
  width: 100%;
  height: 1px;
  margin-top: 100px;
  background-color: var(--graySmooth);
}

.subFooter_Container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 6% 0px 6%;
    /* margin-top: 20px; */
}


.subFooter_Item_Row {
    /* width: 260px; */
    margin-top: 50px;
}

.subFooter_Item_heading {
    font-size: var(--fontSize16);
    font-family: var(--sansSarif);
    color: var(--blackColor);
    font-weight: 600;
}

.subFooter_Items > p {
    padding-top: 22px;
    font-size: var( --fontSizeSmall);
    font-family: var(--sansSarif);
    color: var(--grayColor);
    font-weight: 500;
}

.subFooter_Items > p > a {
    text-decoration: none;
    color: var(--grayColor);
}

.verifiedLogos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }