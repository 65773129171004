@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
:root {
  --greenColor: #05a081;
  --LogoColor: rgba(5, 160, 129, 1);
  --blackColor: #222222;
  --grayColor: #808080;
  --graySmooth: #bbbbbb;
  --borderColor: rgb(221, 221, 221);
  --borderColorTwo: rgba(191,203,218,.6);
  --backgrounG: rgba(209, 212, 217, 0.28);
  --softBlack: #181A18;
  --whiteColor: #fff;
  --white: #fff;
  --softGray: #e7e7ea;
  --iconColor: rgb(180, 180, 180);
  --searchboxShadow: rgba(120, 120, 170, 0.3);
  --boxShadow: rgba(120, 120, 170, 0.15) 0 2px 16px 0;
  --gradientColor: radial-gradient(
    circle,
    rgba(5, 160, 129, 1) 37%,
    rgba(0, 0, 0, 1) 100%
  );
  --headingLinear: linear-gradient(
    50deg,
    hsl(0deg 0% 0%) 0%,
    hsl(168deg 2% 3%) 0%,
    hsl(168deg 5% 6%) 1%,
    hsl(168deg 7% 8%) 1%,
    hsl(168deg 10% 11%) 2%,
    hsl(168deg 13% 13%) 3%,
    hsl(168deg 16% 15%) 5%,
    hsl(168deg 19% 18%) 7%,
    hsl(168deg 23% 19%) 9%,
    hsl(168deg 26% 21%) 12%,
    hsl(168deg 30% 23%) 15%,
    hsl(168deg 34% 25%) 18%,
    hsl(168deg 38% 26%) 22%,
    hsl(168deg 43% 27%) 27%,
    hsl(168deg 48% 28%) 32%,
    hsl(168deg 53% 29%) 38%,
    hsl(168deg 58% 30%) 45%,
    hsl(168deg 65% 31%) 54%,
    hsl(168deg 71% 31%) 63%,
    hsl(168deg 78% 32%) 75%,
    hsl(168deg 86% 32%) 88%,
    hsl(168deg 94% 32%) 100%
  );
  --sansSarif: sans-serif;
  --monrope: 'Manrope', sans-serif;
  --headingFont: 260%;
  --headingFontMedium: 200%;
  --headingFontSmall: 24px;
  --fontSize26: 26px;
  --fontSizeSmall: 14px;
  --fontSize15: 15px;
  --fontSizeM: 16px;
  --fontSize18: 18px;
  --fontSize20: 20px;
  --fontSize22: 22px;
  --fontSizeXSmall: 12px;
  --fontSize10: 10px;
}

