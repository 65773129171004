.account {
  width: 50%;
  margin-left: 100px;
  /* margin: 0% auto; */
  margin-top: 30px;
}

.account-heading {
  font-size: var(--headingFontMedium);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.changeEmail_Label {
  margin-top: 20px;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
}

.input_Container_Account > input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  margin-top: 10px;
  padding-left: 10px;
}

.accountSave_container {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.accountSaveBtn {
  width: 180px;
  height: 45px;
  background-color: var(--greenColor);
  color: var(--whiteColor);
  font-size: var(--fontSizeM);
  font-family: var(--sansSarif);
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accountSaveBtnDisabled {
  width: 180px;
  height: 45px;
  background-color: lightgray;
  color: var(--whiteColor);
  font-size: var(--fontSizeM);
  font-family: var(--sansSarif);
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.deleteAccountBtn {
    width: fit-content;
  color: var(--grayColor);
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  font-weight: 500;
margin-top: 30px;
  cursor: pointer;
}
.accountSaveBtn:hover {
  opacity: 0.8;
}

.account_signOut {
    margin-top: 60px;
}

.account_signOutBtn {
    width: 100%;
    height: 50px;
    background-color: var(--greenColor);
    color: var(--whiteColor);
    font-size: var(--fontSizeM);
    font-family: var(--sansSarif);
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.account_signOutBtn:hover {
    opacity: 0.8;
  }

@media screen and (max-width: 800px) {
  .account {
    width: 90%;
    margin-left: 10px;
  }
}
