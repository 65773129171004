.creator_signup {
  /* width: 30%;
  margin: 0% auto;
  margin-top: 100px; */
  margin: 0% auto;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input_Container {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  margin-top: 20px;
}

.creator_signup-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input_Container > input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
}

.signUpBtn_container {
  width: 100%;
  margin-top: 30px;
}

.signUpBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.signUpBtn:hover {
  opacity: 0.8;
}

.creator_signup_label {
  margin-top: 10px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  font-family: var(--sansSarif);
  color: var(--grayColor);
}

.terms {
  color: var(--blackColor);
} 

@media screen and (max-width: 800px) {
  .creator_signup {
    width: 90%;
  }
  .input_Container {
    display: flex;
    justify-content: center;
  }
  .creator_signup-heading {
    margin-left: 20px;
  }
  .signUpBtn_container {
    display: flex;
    justify-content: center;
  }
  .creator_signup_label {
    font-size: 12px;
  }
}
