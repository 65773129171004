

.ProfileNotApproved {
    text-align: center;
}

.profileReview_heading {
    font-size: var(--fontSize22);
  color: var(--softBlack);
  font-weight: 600;
  font-family: var(--sansSarif);
}
.profileReview_text {
    font-size: var(--fontSize18);
  color: var(--grayColor);
  font-weight: 600;
  font-family: var(--monrope);
}