.editBrandProfile_Contaier {
    display: flex;
    justify-content: end;
    margin-bottom: 7px;
}

.editBrandProfile {
    font-size: 20px;
}

.editBrandProfile_link {
    margin-left: 10px;
    font-size: var(--fontSize16);
    font-weight: 600;
    text-decoration: none;
    color: var(--softBlack);
    font-family: var(--monrope);
    cursor: pointer;
}