.brand {
  width: 70%;
  margin: 0 auto;
  margin-top: 50px;
}

.Brands_hire_heading {
  text-align: center;
  margin-top: 60px;
}

.brandHeading {
  font-size: var(--headingFont);
  font-weight: 600;
  font-family: var(--sansSarif);
  color: var(--blackColor);
}

.typical {
  font-size: var(--headingFont);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Brands_hire_heading > p {
  color: var(--grayColor);
  font-size: 105%;
  font-weight: 400;
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.Brand_SignUpBtn {
  margin-top: 20px;
  width: 220px;
  height: 60px;
  background-color: var(--greenColor);
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: var(--fontSize18);
  color: var(--whiteColor);
  font-family: var(--sansSarif);
  cursor: pointer;
}
.Brand_SignUpBtn:hover {
  opacity: 0.7;
}


@media screen and (max-width: 800px) {
    .brand{
        width: 90%;
    }
    .typical {
      font-size: var(--headingFontMedium);
    }
    .brandHeading {
      font-size: var(--headingFontMedium);
    }
    .Brands_hire_heading > p {
      font-size: 90%;
    }
  
  }
  @media screen and (max-width: 500px) {
    .typical {
      font-size: var(--headingFontSmall);
    }
    .brandHeading{
      font-size: var(--headingFontSmall);
    }
    .Brands_hire_heading > p {
      font-size: 70%;
    }
  }