.sidebar {
    margin: 0;
    padding: 0;
    width: 250px;
    background-color: var(--greenColor);
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  .sideBar_logo_Cont {
    text-align: center;
      background-color: var(--whiteColor);
  }
  .sideBar_logo {
    width: 150px;
  }
  
  
  .sidebar p {
    display: block;
    color: var(--whiteColor);
    font-size: var(--fontSize15);
    font-family: var(--sansSarif);
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .sidebar p.active {
    background-color: var(--blackColor);
    color: white;
  }

  .link {
    text-decoration: none;
  }
  
  .sidebar p:hover:not(.active) {
    background-color: var(--whiteColor);
    color: var(--blackColor);
  }
  
  div.content {
    margin-left: 250px;
    padding: 1px 16px;
    height: 1000px;
  }
  
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar p {
      float: left;
    }
    div.content {
      margin-left: 0;
    }
  }
  
  @media screen and (max-width: 400px) {
    .sidebar p {
      text-align: center;
      float: none;
    }
  }
  