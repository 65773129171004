/* .editInfluencerProfile-container {
  width: 70%;
} */

.edit_Brand_Profile {
    padding: 30px 90px 30px 90px;
  }
  
  .goToProfile {
    width: fit-content;
    font-size: var(--fontSizeSmall);
    font-weight: 500;
    font-family: var(--monrope);
    background-color: var(--LogoColor);
    padding: 5px 25px 5px 25px;
    border-radius: 20px;
    margin-bottom: 15px;
    color: var(--whiteColor);
    cursor: pointer;
  }
  
  .edit_Brand_Profile > h1 {
    font-size: var(--headingFontMedium);
    font-weight: 600;
    font-family: var(--sansSarif);
    background-image: var(--headingLinear);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @media screen and (max-width: 800px) {
    .editBrandProfile-container {
      margin: 0% auto;
      width: 95%;
    }
    .edit_Brand_Profile {
      padding: 30px 20px 30px 20px;
    }
  }
  