
.stepFive {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.stepFive-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input_Container_social {
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
  margin-top: 12px;
  cursor: pointer;
  /* border-radius: 20px; */
}

.input_container_Item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.icons {
  color: var(--blackColor) !important;
  font-size: 20px !important;
  cursor: pointer;
}

.items_text {
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  color: var(--blackColor);
}

.inputs_cont {
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* flex: 1; */
}

.inputName {
  height: 35px;
  flex: 1;
  /* width: 190px; */
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
}

.inputSelect {
    flex: 1;
    /* width: ; */
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .stepFive {
    width: 90%;
  }
  .inputName {
    width: 100%;
    flex: auto;
    /* display: flex; */
  }
  .inputSelect {
    width: 100%;
  }
  .stepFive-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
  .continueBtn{
    margin-bottom: 80px;
  }
}
