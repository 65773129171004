.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.m-2{
  width: 70%;
  margin: 0 auto;
}
.p-1 {
  padding: 0 20px;
}

.w-100 {
  width: 100% !important;
}

.mt-2 {
  margin-top: 10px;
}

.justify-between {
  justify-content: space-between;
}
.f_right{
  float: right;
}
.text-right {
  text-align: right;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 90%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
  /* text-align: center; */
}
.create_offer_main{
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 8% auto;
}
.create_offer_tab1{
  width: 70%;
  margin: 3% auto;
  font-family: var(--monrope);
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.card_input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}

.card_inp {
  border: none;
  margin-left: 4px;
  width: 100%;
  max-width: 300px;
}

.card_inp:focus-visible {
  border: none !important;
  outline: none !important;
}

.justify-end {
  display: flex;
  justify-content: end;
}

.pay_detail {
  padding: 40px;
}

.font16 {
  font-size: 16px;
  font-family: var(--monrope);
}

.width40 {
  width: 300px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.fontpara {
  font-size: 12px;
}

@media screen and (max-width: 1420px) {
  .offer_container {
    flex-wrap: wrap;
  }

  .creditContainer {
    width: 100%;
  }

  .pay_detail {
    width: 100%;

  }
}

@media screen and (max-width: 900px) {
  .Detail {
    width: 85% !important;

  }
}

@media screen and (max-width: 800px) {
  .editInfluProfile {
    padding: 30px 1px 30px 1px;
    margin: 0 auto;
  }

  .saveChangesBtn {
    justify-content: center
  }
}

@media screen and (max-width: 650px) {
  .card_inputs {
    flex-wrap: wrap;
  }

  .updateDetailInput {
    width: 94%;
    margin: 5px;
  }
}


/* additional */
.headercontainer {
  border-bottom: 2px solid #abe150;
}

.padding {
  padding: 10px 100px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header span {
  font-weight: 700;
}

header span img {
  width: 20px;
  padding-right: 5px;
}

.aligncenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps {
  padding: 30px 0px;
}

.steps ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #f4f4f4; */
  background-color: rgba(191,203,218,.12);
}

.steps li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50%;

}

.steps li:not(:last-child) {
  padding-right: 75px;
}

.active {
  color: var(--greenColor);
  padding: 10px 0;
  /* background-color: #eaeaea; */
  background-color: rgba(191,203,218,.22);
  border-radius: 0 20px 20px 0;
  cursor: pointer;
}
.active > .spans:first-child{
  margin-right: 7px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border: 1px solid rgba(191,203,218,.52);
  border-radius: 50%;
  border: 2px solid rgb(0, 219, 134) !important;
  color: rgb(0, 198, 121);
  background-color: rgba(0, 221, 136, 0.16);
}

.normal {
  cursor: pointer;
}

li .spans:first-child {
  width: 26px;
  height: 26px;
  border: 2px solid;
  border-radius: 50%;
  padding: 1px 0px;
  margin-right: 7px;
  margin-left: 7px;
  text-align: center;
  line-height: 26px;

}

li .spans:last-child {
  /* padding-bottom: 5px; */
}

.active .spans:last-child {
  /* border-bottom: 2px solid var(--greenColor); */
}

li {
  position: relative;
}

li .spans:last-child {
  display: inline-block;
  color: #000;
  position: relative;
  z-index: 1;
}

/* li:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 112px;
    right: 0;
    z-index: -1;
    border-top: 3px dotted #bababa;
  } */

.stepped .spans {
  margin-right: 2px;
  border: 2px solid var(--greenColor);
  padding: 5px 30px;
  color: var(--greenColor);
  border-radius: 20px;
  cursor: pointer;
}

.stepscompleted .spans {
  margin-right: 2px;
  border: 2px solid var(--greenColor);
  padding: 5px 30px;
  color: var(--greenColor);
  border-radius: 20px;
  cursor: pointer;
}

.stepped .spans:hover {
  color: #767676;
}

.stepscompleted .spans:hover {
  color: #767676;
}