.banner {
  padding: 0 6% 0px 6%;
  margin-top: 70px;
}

.banner_container {
  width: 100%;
  height: 400px;
  border-radius: 15px;
  background-color: blue;
}

.banner_bg_Image {
  border-radius: 15px;
  
}
.banner_Items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 50px;
}

.banner_Heading {
  font-size: var(--fontSize26);
  font-family: var(--sansSarif);
  color: var(--whiteColor);
  font-weight: 600;
  width: 40%;
}

.banner_desc {
  margin-top: 10px;
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  color: var(--whiteColor);
  font-weight: 500;
}
.searchInflencer_Btn {
  margin-top: 15px;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  color: var(--blackColor);
  font-family: var(--sansSarif);
  font-size: var(--fontSizeSmall);
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}

.searchInflencer_Btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .banner_Items {
    margin-left: 0px;
    text-align: center;
  }
  .banner_Heading {
    font-size: var(--fontSize18);
    width: 100%;
  }
  .button_Container {
    display: flex;
    justify-content: center;
  }
}
