.userProfile {
  width: 85%;
  /* display: flex; */
  margin: 0% auto;
  margin-top: 70px;
}

.sidebar__ {
  width: 15%;
  background-color: red;
}

.sidebar__content {
  width: 85%;
  padding: 20px;
}

.userProfile_Container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.userProfile_desc {
  width: 55%;
  height: fit-content;
}

.userProfile_Images_links_Detail {
  width: 40%;
}

.userProfiles_Details {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.userProfile_Title {
  font-size: var(--fontSize26);
  font-weight: 500;
  font-family: var(--sansSarif);
}

.userProfile_description {
  font-size: var(--fontSizeSmall);
  font-weight: 300;
  font-family: var(--sansSarif);
  color: var(--grayColor);
  line-height: 1.4;
  margin-top: 10px;
}

.userProfile_influencer_Image {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: var(--boxShadow);
  border: 1px solid gray
}

.userProfile_influencer_Name {
  font-size: var(--fontSize16);
  font-weight: 500;
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.userProfile_influencer_location {
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--graySmooth);
  font-family: var(--sansSarif);
}

.userProfile_influencer_Social_Icons_Container {
  display: flex;
  gap: 7px;
  margin-top: 5px;
}

.userProfile_influencer_Social_Icons {
  font-size: var(--fontSize20);
  color: var(--softBlack);
  cursor: pointer;
}

.userProfile_Packages_Container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.userProfile_Packages {
  width: 55%;
  height: fit-content;
}

.userProfile_Packages_Select_Cont {
  width: 40%;
  box-shadow: var(--boxShadow);
  padding: 15px;
  border-radius: 10px;
  height: fit-content;
}

.userProfile_Packages_heading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.userProfile_Packages_heading>p {
  font-size: var(--fontSize20);
  font-weight: 500;
  color: var(--blackColor);
  font-family: var(--sansSarif);
}

.userProfile_Packages_Select {
  width: 30%;
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
}

.userProfile_Packages_Card {
  margin-top: 20px;
  border-radius: 10px;
  border-left: 8px solid #bfc6ca;
  box-shadow: var(--boxShadow);
  padding: 15px;
  cursor: pointer;
}

.userProfile_Packages_Card_platform {
  font-size: var(--fontSizeXSmall);
  font-weight: 500;
  color: var(--graySmooth);
  font-family: var(--sansSarif);
}

.userProfile_Packages_Card_Title_Cont {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize16);
  font-weight: 600;
  color: var(--blackColor);
  font-family: var(--sansSarif);
  margin-top: 5px;
}

.circle_selected_Cont {
  display: flex;
  align-items: center;
  gap: 15px;
}

.circle_Not_selected {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid #bfc6ca;
}

.circle_selected {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #bfc6ca;
}

.userProfile_Packages_Card_desc {
  font-size: var(--fontSizeSmall);
  font-weight: 300;
  font-family: var(--sansSarif);
  color: var(--grayColor);
  line-height: 1.4;
  margin-top: 10px;
}

.userProfile_Packages_Select_title {
  font-size: var(--fontSize18);
  font-weight: 500;
  color: var(--blackColor);
  font-family: var(--sansSarif);
}

.userProfile_Packages_Select_Price {
  font-size: var(--fontSize20);
  font-weight: 500;
  color: var(--blackColor);
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.userProfile_Packages_Select_Offer {
  font-size: var(--fontSize18);
  font-weight: 500;
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.userProfile_Packages_Select_desc {
  font-size: var(--fontSizeXSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.faq {
  font-size: var(--fontSize18);
  font-weight: 500;
  color: var(--blackColor);
  font-family: var(--sansSarif);
  margin: 20px 0px 20px 0px;
}

.loader {
  display: flex;
  justify-content: center;
}

.no_User_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.no_User_Container>h1 {
  font-size: 32px;
  font-weight: 600;
  color: var(--softBlack);
  font-family: var(--monrope);
}

.editInfluencerProfile_Contaier {
  display: flex;
  align-items: center;
}

.editInfluencerProfile {
  font-size: 20px;
}

.editInfluencerProfile_link {
  margin-left: 10px;
  font-size: var(--fontSize16);
  font-weight: 600;
  text-decoration: none;
  color: var(--softBlack);
  font-family: var(--monrope);
  cursor: pointer;
}


.stepOne_label {
  cursor: pointer;
}

#scrollbar1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

#scrollbar1::-webkit-scrollbar {
  margin-left: 5px;
  width: 10px;
  background-color: #F5F5F5;
}

#scrollbar1::-webkit-scrollbar-thumb {
  background-color: var(--greenColor);
  border: 1px solid #555555;
  border-radius: 10px;
}

.coverImage_Container {
  border-radius: 8px 0 0 8px;
}

.image0 {
  border-radius: 8px 0 0 8px;

}

.image0:hover {
  opacity: 0.7;
}

.userProfile_Packages_Card_packageDeadline {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  font-size: var(--fontSizeXSmall);
  font-weight: 500;
  color: var(--graySmooth);
  font-family: var(--sansSarif);
}

.time_deadline {
  color: var(--softBlack);
}

.accoudion_Desc {
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--graySmooth);
  font-family: var(--sansSarif);
}



@media screen and (max-width: 800px) {
  .userProfile {
    width: 90%;
  }

  .userProfile_desc {
    width: 100%;
    margin-top: 20px;
  }

  .userProfile_Images_links_Detail {
    width: 100%;
    margin-top: 20px;
  }

  .userProfile_Packages {
    width: 100%;
  }

  .userProfile_Packages_Select_Cont {
    width: 100%;
  }
}


/* Grid CSS */

.grid {
  display: grid;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr;
  grid-auto-rows: 250px;
  grid-gap: 5px;
}

.grid :nth-child(10n + 8) {
  grid-column: 1;
}

.grid :nth-child(10n + 9) {
  grid-column: 2;
}

.grid :nth-child(10n + 2) {
  grid-column: 4;
  border-radius: 0 8px 0 0;
}

.grid :nth-child(10n + 3) {
  grid-column: 4;
}

.grid :nth-child(10n + 1),
.grid :nth-child(10n + 10) {
  grid-area: span 2 / span 2;
  border-radius: 8px 0 0 8px;
}

.grid :nth-child(2):last-child {
  grid-area: 1 / 3 / span 2 / span 2;
  border-radius: 0 8px 8px 0;
}

.grid :nth-child(2) {
  grid-area: 1 / 3 / span 1 / span 2;

}

.grid :nth-child(3) {
  grid-area: 2 / 3 / span 1 / span 1;

}

.grid :nth-child(3):last-child {
  grid-area: 2 / 3 / span 1 / span 2;
  border-radius: 0 0 8px 0;
}


.grid :nth-child(4):last-child {
  grid-area: 2 / 4 / span 1 / span 1;
  border-radius: 0 0 8px 0;
}

/**/
.grid {
  /* max-width: 1253px; */

  margin: auto;
  counter-reset: num;
}

.grid * {
  border: 1px solid gray;
  font-size: 30px;
  box-sizing: border-box;
  font-family: sans-serif;
  display: grid;
  place-content: center;
}

/* .grid *:before {
  content: counter(num);
  counter-increment: num;
} */
.grid>div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-image: url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'); */
}

@media screen and (max-width:780px) {
  .grid {
    grid-auto-rows: 140px
  }

  .grid :nth-child(10n + 2) {
    grid-column: 1;
  }

  .grid :nth-child(2) {
    grid-area: span 2 / span 2;
    border-radius: 0;
  }

  .grid :nth-child(10n + 1) {
    grid-area: span 2 / span 2;
    border-radius: 0;

  }


  /* for 3 images */
  .grid :nth-child(10n + 3) {
    grid-column: 1;

  }

  .grid :nth-child(3):last-child {
    grid-area: span 2 / span 2;
    border-radius: 0;
  }

  /* for 4 images */
  .grid :nth-child(3) {
    grid-area: span 2 / span 2;
    border-radius: 0;
  }

  .grid :nth-child(4):last-child {
    grid-area: span 2 / span 2;
    border-radius: 0;
  }
}