.login {
  margin: 0% auto;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

}
.w-70{
  overflow: hidden;
width: 70%;
height: calc(100vh + 15px);
background-image: url("../../images/bg01.jpg");
background-repeat: no-repeat;
background-size: cover;
/* background-position:top; */
background-position: top center;
/* background-attachment: fixed; */
}
.w-30{
  width: 30%;
  margin: 0 48px;
  padding-top: 4%;

}
.w-full{
  
  width: 100%;
}
.login-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.resetPassword {
  width: fit-content;
  margin: 0% auto;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  font-family: var(--sansSarif);
  padding-top: 5px;
  cursor: pointer;
  /* color: var(--graySmooth); */
  color: var(--grayColor);
}

.resetPassword:hover {
  color: var(--graySmooth);
}

@media screen and (max-width: 800px) {
  .login {
    width: 90%;
  }
  .w-70{
    display: none;
  }
  .w-30{
    width: 100%;
  }
}
