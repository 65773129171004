.ShareInfluProfile {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    /* width: fit-content; */
    gap: 8px;
    align-items: center;
    
}
.ShareInfluProfile > span {
    color: var(--softBlack);
    font-family: var(--monrope);
    font-size: var(--fontSizeSmall);
    font-weight: 600;
    cursor: pointer;
}

.heartIcon {
    font-size: 18px;
}
.shareProfileLink {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--borderColor);
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
}
.shareProfileLink > p {
    color: var(--softBlack);
    font-family: var(--monrope);
    font-size: var(--fontSizeSmall);
}

.profile_userName {
    text-transform: capitalize;
}

.share_icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}