.stepOne {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.input_Container {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  margin-top: 20px;
}

.stepOne-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input_Container > input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
}
.input_Container > input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}



.cityInput {
  font-size: var(--fontSize15) !important;
  font-family: var(--sansSarif) !important;
  color: var(--graySmooth) !important;
}

.citiesPara {
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  color: var(--graySmooth);
}
.cityName {
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  color: var(--grayColor);
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

.stepOne_label {
  margin-top: 10px;
  text-align: center;
  font-size: var(--fontSize15);
  font-weight: 500;
  font-family: var(--sansSarif);
  color: var(--grayColor);
}

@media screen and (max-width: 800px) {
  .stepOne {
    width: 90%;
  }
  .input_Container {
    display: flex;
    justify-content: center;
  }
  .stepOne-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
  .stepOne_label {
    font-size: 12px;
  }
}
