.zoomLabel {
    margin-top: 5px;
    font-size: var(--fontSizeSmall);
    font-family: var(--sansSarif);
    color: var(--blackColor);
    font-weight: 500;
}


.dialogBox {
    position: relative;
    height: 70vh;
    width: 26vw;
    border: 1px solid black;
}

@media screen and (max-width: 1440px) {
    .dialogBox {
        position: relative;
        height: 50vh;
        width: 30vw;
    }
}

@media screen and (max-width: 1024px) {
    .dialogBox {
        position: relative;
        height: 50vh;
        width: 50vw;
    }
}
@media screen and (max-width: 800px) {
    .dialogBox {
        position: relative;
        height: 40vh;
        width: 66vw;
    }
}