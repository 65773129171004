.stepTen {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.stepTen-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phoneInput {
  width: 98%;
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  font-family: var(--sansSarif);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  margin-top: 10px;
}

.phoneInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}

.formatPhone {
  font-size: var(--fontSizeXSmall);
  font-weight: 500;
  font-family: var(--sansSarif);
  color: var(--graySmooth);
  margin-top: 5px;
}
.number {
  color: var(--blackColor);
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .stepTen {
    width: 90%;
  }

  .stepTen-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
  /* .stepOne_label {
      font-size: 12px;
    } */
}
