

.allInfluencers_Container {
    margin-top: 10px;
    /* border: 1px solid black; */
    border-radius: 5px;
    /* padding: 10px; */
    box-shadow: var(--boxShadow);
}

.allInfluencers_dropdownContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 10px; */
    padding: 10px;
    cursor: pointer;
    /* border-bottom: 1px solid var(--borderColor); */
}

.separator {
    border-bottom: 1px solid var(--borderColor);
}

.allInfluencers_Profile_Cont {
    display: flex;
    gap: 15px;
    align-items: center;
}

.allInfluencers_Profile {
    background-color: black;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: var(--boxShadow);
}

.allInfluencers_ProfileName {
    font-size: 14px;
    color: var(--softBlack);
    font-weight: 500;
    font-family: var(--sansSarif);
}

.expandInfluencerProfile {
    display: flex;
    padding: 0 20px 0 20px;
    flex-wrap: wrap;
    /* padding: 20px; */
    /* justify-content: space-between; */
}
.expandInfluencerProfile> div {
    width: 50%;
}
.profiles_menues {
    font-size: 14px;
    color: var(--softBlack);
    font-weight: 500;
    font-family: var(--sansSarif);
    padding: 5px;
    /* width: 170px; */
}
.profiles_socialLink {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--softBlack);
    font-weight: 500;
    font-family: var(--sansSarif);
    padding: 5px;
    /* width: 170px; */
}
.profiles_socialLink> div {
    display: flex;
    gap: 10px;
    padding-left: 10px;
}

.profile_socialLinkIcon {
    font-size: 16px;
    cursor: pointer;
}

.profiles_menues > span{
    font-size: 14px;
    color: var(--graySmooth);
    font-weight: 500;
    font-family: var(--sansSarif);
} 

.profile_Packages_Card {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: 10px 20px 10px 20px;
}

.profile_Package {
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow);
    border: 1px solid var(--softBlack);
}
.profile_Package > p {
    font-size: 14px;
    color: var(--softBlack);
    font-weight: 500;
    margin-top: 10px;
    font-family: var(--sansSarif);
}
.profile_Package > p > span{
    font-size: 14px;
    color: var(--graySmooth);
    font-weight: 500;
    margin-top: 10px;
    font-family: var(--sansSarif);
}
.profiles_Packages_Heading{
    font-size: var(--fontSizeSmall);
    color: var(--softBlack);
    font-weight: 500;
    margin-top: 10px;
    margin-left: 25px;
    font-family: var(--sansSarif);
}

.approved_DeniedBtn_cont {
    display: flex;
    justify-content: end;
    margin: 20px;
}



.approvedBtn {
    background-color: var(--greenColor);
    color: var(--whiteColor);
    font-family: var(--sansSarif);
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .expandInfluencerProfile> div {
        width: 100%;
    }
    .profile_Package {
        width: 100%;
    }
  }
  