.featured_Influencers {
  padding: 0 6% 0px 6%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.featured_heading {
  font-size: var(--headingFontSmall);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 600;
}
.feature_heading_desc {
  display: flex;
  justify-content: space-between;
}

.feature_heading_desc_One {
  color: var(--grayColor);
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  margin-top: 5px;
}

.featured_seeAll {
  color: var(--blackColor);
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  margin-top: 5px;
  font-weight: 700;
}

.listing-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 1%;
}
.featured-profile-listing-holder {
  width: 50%;
  /* min-width: 230px; */
}

.link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.profile-listing-img-holder {
  width: 100%;
  height: 330px;
  position: relative;
  border-radius: 9px;
  background-color: black;
  overflow: hidden;
}

.profile-listing-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 8px;
  -webkit-mask-image: linear-gradient(
    to top,
    rgb(0 0 0 / 50%) 10%,
    rgba(0, 0, 0, 1)
  );
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.13, 1.02) 0s;
}
.profile-listing-img:hover {
  transform: scale(1.1);
}

.profile-listing-owner-holder {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
}

.profile-listing-owner-info-holder {
  display: flex;
  flex-direction: column;
}

.profile-listing-owner-name {
  display: flex;
  align-items: center;
  font-size: 80%;
  color: white;
  font-weight: 600;
  font-family: var(--sansSarif);
}

.verified-badge {
  width: 14px;
  margin-left: 4px;
}

.profile-listing-owner-location {
  font-size: 72%;
  color: white;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin-top: 1px;
}

.profile-listing-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0;
}

.profile-listing-category {
  color: var(--lightGray);
  font-size: 75%;
  font-family: var(--sansSarif);
}

.profile-listing-price {
  font-weight: 600;
  font-size: 95%;
  font-family: var(--sansSarif);
}

.profile-listing-title {
  font-size: 90%;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin: 0;
}

.card_platform_price {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.platform {
  color: var(--graySmooth);
  font-family: var(--sansSarif);
  font-size: var(--fontSizeSmall);
  font-weight: 500;
}
.price {
  color: var(--blackColor);
  font-family: var(--sansSarif);
  font-size: 14px;
  font-weight: 600;
}

.currency {
  color: var(--graySmooth);
  font-family: var(--sansSarif);
  font-size: 14px;
  font-weight: 500;
}
.description {
  margin-top: 5px;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-smooth: always;
}

.slick-slide {
  padding:10px;
}

@media screen and (max-width: 1000px) {
  .listing-row {
    overflow-x: auto;
    /* overflow: hidden; */
    gap: 10px;
  }
  .listing-row::-webkit-scrollbar {
    width: 0px;
  }
  .featured-profile-listing-holder {
    width: 230px;
  }
  .profile-listing-img-holder {
    /* width: 230px; */
    width: 265px;
    height: 230px;
  }
}

@media screen and (max-width: 900px){
  .profile-listing-img-holder {
    /* width: 230px; */
    width: 235px;
    height: 230px;
  }
}

@media screen and (max-width: 800px) {
  .featured-profile-listing-holder {
    width: 210px;
  }
  .profile-listing-img-holder {
    width: 200px;
    height: 230px;
  }
  .featured_Influencers .slick-slide {
    padding:10px;
  }
}

@media screen and (max-width: 700px) {
  .featured_Influencers .slick-slide {
    padding:0px 12px;
  }
}

@media screen and (max-width: 650px) {
  .featured_Influencers .slick-slide {
    padding-right: 30px;
  }
}

@media screen and (max-width: 600px) {
  .featured_Influencers .slick-slide {
    padding-right: 40px;
  }
}

@media screen and (max-width: 550px) {
  .profile-listing-img-holder{
    width:100%;
  }
  .featured_Influencers .slick-slide {
    padding-right: 0px;
    /* width: 190px !important; */
  }
  .slick-list {
    width: 98vw !important;
}
}
@media screen and (max-width: 550px) {
  .featured-profile-listing-holder{
    width: 24%;
    min-width: 180px;
  }
  .profile-listing-img-holder {
    height: 180px;
}
.feature_heading_desc_One{
  font-size: var(--fontSizeXSmall);
}
.featured_seeAll{
  font-size: var(--fontSizeXSmall);
}
.listing-row{
  margin-top: 5%;
}
}
@media screen and (max-width: 500px) {
  .featured_Influencers .slick-slide {
    padding-right: 12px;
    padding-left: 0px;
  }
  .featured_Influencers .slick-slide:last-child {
    padding-right: 0px;
    padding-left: 0px;
  }
}


@media screen and (max-width: 450px) {
  .profile-listing-img-holder{
    width:100%;
  }
  .featured_Influencers .slick-slide {
    padding-right: 22px;
    padding-left: 0px;
  }
  .featured_Influencers .slick-slide:last-child {
    padding-right: 0px;
    padding-left: 0px;
  }
  .slick-list {
    width: 105vw !important;
}
}