

.find_hire_heading {
  text-align: center;
  margin-top: 60px;
}
.find_hire_heading > h1 {
  font-size: var(--headingFont);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.find_hire_heading > p {
  color: var(--grayColor);
  font-size: 105%;
  font-weight: 400;
  font-family: var(--sansSarif);
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .find_hire_heading > h1 {
    font-size: var(--headingFontMedium);
  }
  .find_hire_heading > p {
    font-size: 90%;
  }
}
@media screen and (max-width: 500px) {
  .find_hire_heading > h1 {
    font-size: var(--headingFontSmall);
  }
  .find_hire_heading > p {
    font-size: 70%;
  }
}
