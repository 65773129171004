.searchBox {
  padding: 0 1% 0px 1%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.searchBox_Container {
  width: 75%;
  box-shadow: 1px 2px 26px -16px rgb(20 20 24);
  height: 65px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.search_input_container {
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search_Input_div {
  width: 46%;
  height: 60px;
  border-radius: 30px;
  font-size: var(--fontSizeM);
  font-family: var(--sansSarif);
  font-weight: 500;
  padding-left: 9px;
}
.search_Input_div:hover {
  box-shadow: 1px 2px 22px -16px rgba(20, 20, 24, 1);
}

.input_label {
  margin-top: 12px;
  /* margin-top: 10px; */
  /* margin-left: 20px; */
  margin-left: 10px;
  font-size: var(--fontSizeSmall);
  font-family: var(--sansSarif);
  font-weight: 700;
  font-size: 80%;
}

.search_Input {
    width: 80%;
  height: 25px;
  /* margin-left: 20px; */
  margin-left: 6px;
  outline: none !important;
  border: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 1px !important;
  text-overflow: "" !important;
}

.search_Input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  color: var(--lightGray);
  font-weight: 500;
  font-size: var(--fontSize15);
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}


.options_container {
    position: relative;
    top: 30%;
    /* left: 50%; */
    /* height: 300px; */
    height: auto;
    overflow-x: auto;
    margin-top: 5px;
    z-index: 100;
    /* border-radius: 5px; */
    border-radius: 30px;
    
    box-shadow: 0px 0px 7px var(--searchboxShadow);
    background-color: var(--whiteColor);
    padding: 10px;
}

.options_Heading {
  padding: 10px;
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
  border-radius: 5px;
  font-size: var(--fontSizeSmall);
}
.options_Heading > span{
  font-size: 18px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

}

.option_inner {
  /* margin: 2px 7px 2px 7px; */
  margin: 2px;
  padding: 1px 2px;
  /* border: 1px solid var(--graySmooth); */
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
  border-radius: 5px;
  font-size: var(--fontSizeSmall);
  /* margin-bottom: 5px; */
  cursor: pointer;
}
.MuiChip-outlined{
  /* height: 29px !important; */
  border-radius: 6px !important;
  background-color: #F1F1F1 !important;
  border-color: transparent !important;
}
.MuiChip-outlined:hover{
  cursor: pointer;
  background-color: #d6d6d6 !important;
}

.searchButton {
  /* width: 55px; */
  width: 51px;
  margin-right: 10px;
}

.searchButton > button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--greenColor);
  font-family: var(--sansSarif);
  color: var(--whiteColor);
  font-weight: 600 !important;
  font-size: var(--fontSize18);
  border-radius: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.searchButton > button:hover{
  opacity: 0.8;
}

.platforms {
  padding: 14px;
  margin: 10px;
  cursor: pointer;
  font-size: var(--fontSize16);
  font-family: var(--sansSarif);
}

.platforms:hover {
  background-color: var(--softGray);
  border-radius: 10px;
}

.filter_Seperator{
  border-right: 1px solid #e6e6e6;
  height: 36px;
}

@media screen and (max-width: 1000px) {
  .searchBox_Container {
    padding: 10px;
  }
  .open_Options_Container {
    /* width: 300px; */
  }
  .searchBox_Container {
    height: min-content;
  }
  .search_Input_div {
    width: 100%;
  }
  .searchButton {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .searchButton > button { 
    /* border-radius: 10px; */
    border-radius: 50px;
    margin-top: 10px;
    width: 100%;
  }
}

/* 
.searchBox_Container {
  border: 1px solid var(--graySmooth);
  border-radius: 10px;
  min-height: 140px;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 20px 0px 20px;
  align-items: center;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}



.platform_Search {
  width: 350px;
}
.category_Search {
  width: 350px;
}

.inputs_tags_Container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 55px;
  cursor: pointer;
}


.inputs_tags {
  width: 100%;
  height: 55px;
  border: 1px solid #c6c3c3;
  overflow: auto;
  border-radius: 5px;
}

.inputs_tags_Cont {
  width: 100%;
  height: 55px;
  overflow: auto;
  border-radius: 5px;
}

.input_chip_Container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  gap: 4px;
  padding: 5px;
}

.search_Input_Chip {
  width: 100%;
  border: none;
  outline: none;
  font-size: var(--fontSizeM);
  font-family: var(--sansSarif);
  font-weight: 500;
}

.input_chip {
  padding: 7px 10px 7px 10px;
  border-radius: 20px;
  font-size: 14px;
  box-shadow: 0 0 7px black;
}

.delete_Input_Chip {
  padding: 3px 5px 3px 5px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}

.optionsContainer {
  position: relative;
  top: 0%;
  margin-top: 5px;
  z-index: 100;
  border-radius: 5px;
  box-shadow: 0px 0px 7px black;
  background-color: var(--whiteColor);
  width: 350px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.options_div {
  padding: 3px;
}

.options {
  padding: 2px;
  border: 1px solid var(--graySmooth);
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
  border-radius: 5px;
  font-size: var(--fontSizeSmall);
}

.searchBtnCont {
  text-align: center;
}

.searchBtn {
  width: 200px;
  height: 45px;
  border: none;
  background: #06a081;
  background: -webkit-linear-gradient(top, #06a081, #030303);
  background: -moz-linear-gradient(top, #06a081, #030303);
  background: linear-gradient(to bottom, #06a081, #030303);
  cursor: pointer;
  color: white;
  font-size: 16px;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
}

.searchBtn:hover {
  transition: all 0.5s ease-in-out;
  clip-path: polygon(0% 0, 100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%);
  border-radius: 5px;
} */

/* width */
.options_container::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.options_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.options_container::-webkit-scrollbar-thumb {
  background: var(--greenColor);
}

/* Handle on hover */
.options_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (max-width: 1520px) {
  .search_Input_div{
    width: 45%;
  }
}
@media screen and (max-width: 1220px) {
  .search_Input_div{
    width: 43%;
  }
}
@media screen and (max-width: 1000px) {
  .search_Input_div{
    width: 100%;
  }
  .filter_Seperator{
    border-right: none;
    border-bottom: 1px solid #e6e6e6;
    height: 8px;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .searchBox_Container {
    width: 90%;
  }
  .platform_Search {
    width: 100%;
  }
  .category_Search {
    width: 100%;
    /* margin-top: 50px; */
  }
}