
.collabsCafeGuide {

    padding: 0 6% 0px 6%;
}

.collabsCafe_guide_Container {
  display: flex;
  gap: 20px;
  padding: 0px 10px 0px 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.collabsCafe_guide_Card {
  width: 32%;
  margin-top: 50px;
}

.collabsCafe_guide_Icon {
  width: 22px;
  filter: brightness(0);
  margin-bottom: 10px;
}

.collabsCafe_guide_heading {
  font-size: var(--fontSize22);
  font-family: var(--sansSarif);
  color: var(--blackColor);
  font-weight: 600;
}
.collabsCafe_guide_Desc {
  margin-top: 10px;
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  color: var(--grayColor);
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .collabsCafe_guide_Card {
    width: 100%;
  }
}
