.lovedCreators {
  padding: 0 6% 0px 6%;
  margin-top: 100px;
}

.lovedCreatorsHeading {
  text-align: center;
  font-size: var(--headingFontMedium);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creators_Container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.creators {
  width: 60%;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.chip {
  padding: 20px !important;
  border-radius: 50px !important;
  font-size: var(--fontSizeSmall) !important;
  font-weight: 600;
  font-family: var(--sansSarif);
}

@media screen and (max-width: 800px) {
  .creators {
    width: 100%;
    gap: 5px;
  }
  .chip {
    padding: 5px !important;
    font-size: var(--fontSizeXSmall) !important;
    font-weight: 500;
  }
}
