.InfluencerPaymentRequest_container {
    width: 60%;
    box-shadow: 3px 2px 9px -1px rgba(0, 0, 0, 0.62);
    margin: 0% auto;
    border-radius: 5px;
    padding: 10px;
    margin-top: 30px;
}

.InfluencerPaymentRequest_container>h1 {
    font-family: var(--monrope);
    font-size: var(--fontSize20);
    margin-bottom: 10px;
}


.InfluencerPaymentRequest_details {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.InfluencerPaymentRequest_details>div {
    flex: 1;
    /* width: 45%; */
}

.select_accountType {
    font-family: var(--monrope);
    font-size: var(--fontSizeM);
}

.radios {
    font-family: var(--monrope) !important;
    font-size: var(--fontSizeM) !important;
}

.InfluencerPaymentRequest_label {
    font-family: var(--monrope);
    font-size: var(--fontSizeXSmall);
    color: var(--graySmooth);
}

.payment_request_btn {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 800px) {
    .InfluencerPaymentRequest_container {
        width: 90%;
    }

    .InfluencerPaymentRequest_details {
        /* display: flex; */
        /* gap: 10px; */
        display: block;
    }

    .InfluencerPaymentRequest_details>div {
        display: block;
        width: 100%;
    }

}