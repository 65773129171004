.checkout_main {
    padding: 0 6% 0px 6%;
    margin-top: 50px;
    /* padding-bottom: 50px; */
    /* background-color: blueviolet; */
}

/* .checkout_heading {
    color: var(--greenColor);
    font-size: var(--headingFontMedium);
    font-weight: 500;
    font-family: var(--sansSarif);
    margin-top: 10px;   
} */


.checkout_detail {
    display: flex;
    gap: 30px;
    margin-top: 20px;
    /* justify-content: space-around; */
}
.checkout_detail > div {
    flex: 1 1 0px;
}
.checkout_detail > div > p {
    color: var(--softBlack);
    font-size: 105%;
    font-weight: 400;
    font-family: var(--sansSarif);
    margin-top: 10px;
}

.description_form {
    color: var(--softBlack);
    font-size: 105%;
    font-weight: 400;
    font-family: var(--sansSarif);
    margin-top: 5px;
}


.description_form > textarea {
    width: 100%;
    resize: none;
    color: var(--softBlack);
    font-size: 85%;
    font-weight: 400;
    font-family: var(--sansSarif);
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--grayColor);
} 

.payNow_btn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.payNow_btn > Button{
    height: 40px;
    width: 30%;
}

@media screen and (max-width: 800px) {
    .checkout_main {
        padding: 0 3% 0px 3%;
    }

    .checkout_detail {
        display: flex;
        flex-direction: column;
      
    }
    .checkout_detail > :nth-child(2) {
        margin-top: 10px;
      
    }
    .description_form > textarea {
        width: 100%;
    } 
    .payNow_btn > Button{
        width: 100%;
    }
}