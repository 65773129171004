.error_page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: var(--monrope);
    height: calc(100vh - 115px);
}

.no_page{
    color: var(--greenColor);
    margin: 10px auto;
    font-size: xx-large;
    font-weight: 700;
}

.go_to_home{

    display: block;
    text-align: center;
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 8px;
    box-shadow: var(--grayColor);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    background-color: var(--greenColor);
    padding: 16px;
    width: 200px;
    margin: 15px auto;
    color: white;
}