.Detail {
  width: 65%;
}

.updateDetailInput {
  margin-top: 25px;
}

.updateDetailInput > input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
  margin-top: 5px;
}

.location_Name {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
  margin-top: 5px;
}

.labelText {
  font-size: var(--fontSizeM);
  font-weight: 500;
  margin-bottom: 3px;
  color: var(--graySmooth);
}

.update-Description {
  width: 100%;
  height: 120px;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  margin-top: 5px;
  resize: vertical;
}

.updateGenderSelect {
  width: 101%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--borderColorTwo);
  outline: none;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  padding-left: 10px;
  margin-top: 5px;
}

.saveChangesBtn {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.saveChanges {
  font-size: var(--fontSizeSmall);
  font-weight: 600;

  font-family: var(--monrope);

  color: var(--whiteColor);
}

/* social Media section */

.socialMedia {
  width: 65%;
  margin-top: 25px;
}

/* faqs section */

.updateFaqs {
  width: 65%;
  margin-top: 25px;
}
/* faqs Packages */
.updatePackages {
  width: 100%;
  margin: 0% auto;
  margin-top: 25px;
}

/* Images Section */
.Update-images-section {
  width: 100%;
  margin: 0% auto;
  margin-top: 25px;
}

@media screen and (max-width: 900px) {
  .Detail {
    width: 100%;
  }
  .socialMedia {
    width: 100%;
  }
  .updateFaqs {
    width: 100%;
  }
  .updateGenderSelect{
    max-width: 740px;
  }
}
