.sidebar {
  margin: 0;
  padding: 0;
  width: 250px;
  background-color: var(--greenColor);
  position: fixed;
  height: 100%;
  overflow: auto;
}
/* .sideBar_logo {
  width: 100%;
  background-color: var(--whiteColor);
} */


.sidebar p {
  display: block;
  color: var(--whiteColor);
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
}

.sidebar p.active {
  background-color: var(--blackColor);
  color: white;
}

.sidebar p:hover:not(.active) {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

div.content {
  margin-left: 250px;
  padding: 1px 16px;
  /* height: 1000px; */
}

.content_Inner {
  margin-top: 30px;
}

.noDataImage {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.noDataImage > img {
  width: 60%;
}


@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar p {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar p {
    text-align: center;
    float: none;
  }
}
