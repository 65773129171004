.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--whiteColor);
  align-items: center;
  height: 85px;
  padding: 0 6% 0px 6%;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header_logo {
  width: 170px;
  height: 60px;
  cursor: pointer;
}

.header_Navs {
  display: flex;
  gap: 25px;
}
.header_Navs_LoggedIn {
  display: flex;
  gap: 25px;
}
.header_Navs > a {
  font-size: 14px;
  color: var(--blackColor);
  font-weight: 600;
  font-family: var(--sansSarif);
  text-decoration: none;
  transition-duration: 0.3s;
}
.join_Influencer {
  font-size: 14px;
  background: var(--gradientColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-family: var(--sansSarif);
  text-decoration: none;
  transition-duration: 0.3s;
}
.header_Navs > a:hover {
  opacity: 0.6;
}

.menuItems {
  margin-left: 10px;
  font-size: 15px;
  color: var(--grayColor);
  font-weight: 500;
  font-family: var(--sansSarif);
}
.headerIcons {
  color: var(--graySmooth);
}


@media screen and (max-width: 800px) {
  .header_logo {
    /* width: 110px; */
  }
  .header_Navs {
    display: none;
  }
}
