.stepSix {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.stepSix-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.itemContainer {
  margin-top: 10px;
  height: 300px;
  overflow-x: auto;
}

.item_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* padding: 0px 7px 0px 7px; */
}

.items {
  width: 46%;
  border: 1px solid var(--borderColorTwo);
  cursor: pointer;
  border-radius: 5px;
}

.items:hover {
    background-image: var(--headingLinear);
    color: var(--whiteColor);
}

.items > p {
  text-align: center;
  padding: 14px 0px 14px 0px;
  font-size: var(--fontSize15);
  font-weight: 500;
  color: var(--sansSarif);
  font-family: var(--sansSarif);
}

.selectedItem {
    width: 46%;
    border: 1px solid var(--borderColorTwo);
    cursor: pointer;
    border-radius: 5px;
    background-color: black;
    color: var(--whiteColor);
  }


.selectedItem > p {
  text-align: center;
  padding: 14px 0px 14px 0px;
  font-size: var(--fontSize15);
  font-weight: 500;
  color: var(--sansSarif);
  font-family: var(--sansSarif);
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .stepSix {
    width: 90%;
  }

  .stepFive-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
}

.itemContainer::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.itemContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.itemContainer::-webkit-scrollbar-thumb {
  background: var(--greenColor);
}

/* Handle on hover */
.itemContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
