.card {
  margin: 0% auto;
  margin-top: 50px;
  width: 50%;
  box-shadow: var(--boxShadow);
  padding: 10px;
  border-radius: 10px;
}


.card_logo_container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: var(--greenColor);
  margin: 0 auto;
  box-shadow: var(--boxShadow);
  /* marginTop: "20px" ,display: "flex", justifyContent: "center", alignItems: "center",  borderRadius: "50%", height: 200, width: 200, background: "#F8FAF5", margin: "0 auto" } */
}

.card_success_icon {
  color: var(--whiteColor);
  font-size: 100px;
}

.card_err_msg {
  text-align: center;
  font-size: var(--headingFontMedium);
  font-weight: 500;
  color: var(--greenColor);
  font-family: var(--sansSarif);
}

.card_payment_detail {
  font-size: var(--headingFontSmall);
  font-weight: 500;
  color: var(--greenColor);
  font-family: var(--sansSarif);
}

.card_payment {
  width: 48%;
}

.card_payment>p {
  margin-top: 5px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  color: var(--greenColor);
  font-family: var(--sansSarif);
}

.card_payment>p>span {
  margin-top: 5px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  color: var(--graySmooth);
  font-family: var(--sansSarif);
}

.card_detail {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .card {
    width: 90%;
  }


  .card_logo_container {
    height: 100px;
    width: 100px;
  }

  .card_success_icon {
    font-size: 50px;
  }
  .card_payment {
    width: 100%;
  }

  .card_err_msg {
    font-size: var(--fontSize22);
  }
}