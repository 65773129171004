.Billing_header{
    width: 80%;
    margin: 4% auto;
    font-family: var(--sansSarif);
}

.total_row{
    display: flex;
    flex-wrap: wrap;
    margin: 5% 0 6% 0;
}
.total_holder{
    width: 25%;
    padding: 26px 0 26px 26px;
    border-right: 1px solid rgba(191,203,218,.6);
}
.total_title{
    color: gray;
    margin-bottom: 5px;
    font-size: 90%;
}
.total_num{
    font-weight: 500;
    font-size: 190%;
    margin: 0;
}
.input_form{
    display: flex;
    margin: 2% 0;
}
.input{
    padding: 0 4px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid rgba(191,203,218,.6);
    transition-duration: 0.3s;
    min-height: 32px !important;
    font-weight: 300;
    margin-right: 8px;
    width: 100px;
    appearance: none;
}

.input_form > select{
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve"><path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/><path fill="none" d="M0,0h24v24H0V0z"/></svg>');
    background-position: center right;
    background-repeat: no-repeat;
}

.order_holder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 10px;
    border-bottom: 1px solid rgba(191,203,218,.4);
    font-size: 92%;
}
.order_holder_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 10px;
    border-bottom: 1px solid rgba(191,203,218,.4);
    font-size: var(--fontSizeSmall);
}

.order_title{
    font-weight: 600;
}
.order_title_items {
    font-weight: 500;
}

.orderDate, .orderAmount{
    width: 90px;
}

.orderDetail{
    width: 30%;
}
.orderStatus{
    width: 16%;
}



.no_orders{
    margin: 5% auto;
    text-align: center;
}



@media  screen and (max-width: 800px){
    .total_row {
        justify-content: space-between;
        margin: 5% 0 12% 0;
    }
    .total_holder {
        width: 42%;
        margin: 2% 0;
        padding: 26px 10px;
        border: 1px solid rgba(191,203,218,.6);
        border-radius: 8px;
    }
    .total_title {
        font-size: 80%;
    }
    .total_num {
        font-size: 165%;
    }
}