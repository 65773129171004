.stepsContainer {
  width: 30%;
  margin: 0% auto;
  margin-top: 70px;
}
.stepsContainerOne {
  width: 70%;
  margin: 0% auto;
  margin-top: 70px;
}

.steps_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepsBackCont {
  display: flex;
  gap: 20px;
}
.stepsBackCont > p {
  padding: 8px 12px 8px 12px;
  border-radius: 25px;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  background-color: var(--backgrounG);
  cursor: pointer;
}

.stepsCount {
  background-color: #d6efe2;
  color: green;
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  padding: 8px 12px 8px 12px;
  font-family: var(--sansSarif);
  border-radius: 25px;
}

.InfluencerProfileContainer {
  width: 30%;
  margin: 0% auto;
}

.importantNotes_Text {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--softBlack);
  font-size: var(--fontSizeSmall);
  font-weight: 500;
  margin-top: 10px;
  font-family: var(--sansSarif);
}

.checkedIcon {
  color: var(--greenColor);
  font-size: 10px;
}

/* example profile Modal */
.exampleProfile-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.exampleProfile-content {
  width: 90%;
  height: 80vh;
  margin-top: 100px;
  overflow: auto;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: var(--boxShadow);
}

.exampleProfile_modalHeading_Container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.exampleProfile_modalHeading {
  font-size: var(--fontSize18);
  font-family: var(--sansSarif);
  font-weight: 500;
}

.exampleProfile_modalClose {
  text-align: end;
  color: var(--blackColor);
  /* font-weight: 600; */
  font-size: var(--fontSize26);
  font-family: var(--sansSarif);
  transform: rotate(45deg);
  cursor: pointer;
}


.exampleProfile-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.exampleProfile-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.exampleProfile-content::-webkit-scrollbar-thumb {
  background: var(--greenColor);
}

/* Handle on hover */
/* .exampleProfile-content::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

@media screen and (max-width: 800px) {
  .stepsContainer {
    width: 90%;
  }
  .stepsContainerOne {
    width: 90%;
  }
  .stepsBackCont {
    gap: 10px;
  }
  .stepsBackCont > p {
    padding: 5px 8px 5px 8px;
    font-size: var(--fontSizeSmall);
  }
  .stepsCount {
    padding: 5px 8px 5px 8px;
    font-size: var(--fontSizeSmall);
  }
  .InfluencerProfileContainer {
    width: 90%;
  }
}
