.allInfluencer_listing-row {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  margin-top: 1%;
  flex-wrap: wrap;

}

.allInfluencer_featured-profile-listing-holder {
  width: 23%;
  cursor: pointer;
  /* margin-top: 10px; */
  /* min-width: 230px; */
}



.allInfluencer_profile-listing-img-holder {
  width: 100%;
  height: 330px;
  position: relative;
  border-radius: 9px;
  background-color: black;
  overflow: hidden;
}

.allInfluencer_profile-listing-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  border-radius: 8px;
  -webkit-mask-image: linear-gradient(to top,
      rgb(0 0 0 / 50%) 10%,
      rgba(0, 0, 0, 1));
  transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.13, 1.02) 0s;
}

.allInfluencer_profile-listing-img:hover {
  transform: scale(1.1);
}

.allInfluencer_profile-listing-owner-holder {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
}

.allInfluencer_profile-listing-owner-info-holder {
  display: flex;
  flex-direction: column;
}

.allInfluencer_profile-listing-owner-name {
  display: flex;
  align-items: center;
  font-size: 80%;
  color: white;
  font-weight: 600;
  font-family: var(--sansSarif);
}

.allInfluencer_verified-badge {
  width: 14px;
  margin-left: 4px;
}

.allInfluencer_profile-listing-owner-location {
  font-size: 72%;
  color: white;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin-top: 1px;
}

.allInfluencer_profile-listing-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0;
}

.allInfluencer_profile-listing-category {
  color: var(--lightGray);
  font-size: 75%;
  font-family: var(--sansSarif);
}

.allInfluencer_profile-listing-price {
  font-weight: 600;
  font-size: 95%;
  font-family: var(--sansSarif);
}

.allInfluencer_profile-listing-title {
  font-size: 90%;
  font-weight: 300;
  font-family: var(--sansSarif);
  margin: 0;
}

.allInfluencer_card_platform_price {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.allInfluencer_platform {
  color: var(--graySmooth);
  font-family: var(--sansSarif);
  font-size: var(--fontSizeSmall);
  font-weight: 500;
}

.allInfluencer_price {
  color: var(--blackColor);
  font-family: var(--sansSarif);
  font-size: 16px;
  font-weight: 600;
}

.allInfluencer_description {
  margin-top: 5px;
  color: var(--grayColor);
  font-family: var(--sansSarif);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-smooth: always;
}

.heart {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
  cursor: pointer;
}

.pagination_container {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}

@media screen and (max-width: 1150px) {
  .listing-row {
    overflow-x: auto;
    gap: 10px;
  }

  .slick-next{
    display: none !important;
  }
  /* .listing-row::-webkit-scrollbar {
      width: 0px;
    } */
  .featured-profile-listing-holder {
    /* width: 230px; */
  }

  /* .profile-listing-img-holder {
    width: 230px;
    height: 230px;
  } */
}

@media screen and (max-width: 800px) {

  .allInfluencer_featured-profile-listing-holder {
    width: 32%;
    margin-top: 30px;
  }

  .allInfluencer_featured-profile-listing-holder {
    width: 100%;
  }

  .allInfluencer_profile-listing-img-holder {
    width: 100%;
    height: 330px;
  }
}