.seenIn {
  padding: 0 6% 0px 6%;
  margin-top: 70px;
}

.seenIn_Heading {
    text-align: center;
    color: var(--grayColor);
    font-family: var(--sansSarif);
    font-size: var(--fontSizeSmall);
    font-weight: 600;
}

.seenIn_Images_Container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 50px; */
}

.seenInImages {
    width: 135px;
    object-fit: fill;
    filter: sepia(1%) brightness(87%) saturate(157%) opacity(67%);
    -webkit-filter: sepia(1%) brightness(87%) saturate(157%) opacity(67%);
    -moz-filter: sepia(1%) brightness(87%) saturate(157%) opacity(67%);
}