.our-team-heading {
    text-align: center;
    color: var(--greenColor);
    font-size: var(--headingFontMedium);
    font-family: var(--sansSarif);
    margin-top: 5px;
}

.our-team-title {
    text-align: center;
    color: var(--grayColor);
    font-size: var(--fontSize20);
    font-family: var(--sansSarif);
    margin-top: 5px;
}

.our-team-main-card {
    padding: 40px 5% 0 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.our-team-main-card>div {
    width: 30%;
    height: 500px;
    box-shadow: var(--boxShadow);
  
}

.our_team-social_links {
    display: flex;
    justify-content: center;
    gap: 20px;
}



.our_team-social_links_hide {
    /* display: none; */
}

.our_team-social_links>div {
    width: 60px;
    height: 60px;
    /* border: 1px solid var(--greenColor);
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.insta_icon {
    color: #e1306c !important;
    font-size: 20px !important;
    cursor: pointer;
}

.tiktok_icon {
    color: #ff0050 !important;
    font-size: 20px !important;
    cursor: pointer;
}

.twitter_icon {
    color: #00acee !important;
    font-size: 20px !important;
    cursor: pointer;
}

.fb_icon {
    color: #4267b2 !important;
    font-size: 20px !important;
    cursor: pointer;
}

.our-team-name {
    text-align: center;
    color: var(--greenColor);
    font-size: var(--fontSize15);
    font-family: var(--sansSarif);
    margin-top: 5px;
}

.our-team-designation {
    text-align: center;
    color: var(--grayColor);
    font-size: var(--fontSize15);
    font-family: var(--sansSarif);
    margin-top: 5px;
}

@media screen and (max-width: 800px) {
    .our-team-main-card>div {
        width: 100%;
        margin-top: 80px;
    }
}