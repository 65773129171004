.collabsCafeWorks {
  padding: 0 6% 0px 6%;
  margin-top: 100px;
  /* margin-bottom: 100px; */
}
.collabsCafeWorks_heading {
  font-size: var(--fontSize26);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 600;
}

.collabsCafeWorks_heading_desc {
  color: var(--grayColor);
  font-size: var(--fontSize15);
  font-family: var(--sansSarif);
  margin-top: 5px;
}

.collabsCafeWorks_Card_Cont {
  /* width: 100%; */
  width: 33%;
  margin-top: 20px;
}

.collabsCafeWorks_Card_Container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
}

.collabsCafeWorks_Card {
  width: 100%;
  height: 300px;
  /* border-radius: 10px; */

  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: black;
}

.collabsCafeWorks_Card_Bg {
  width: 100%;
  height: 100%;
}

.collabsCafeWorks_Card_Bg {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
}

.collabsCafeWorks_bg_text {
  padding: 15px;
}

.collabsCafeWorks_bg_text_Name {
  color: var(--whiteColor);
  font-family: var(--sansSarif);
  font-size: var(----fontSize18);
  font-weight: 600;
  margin-top: 10px;
}

.collabsCafeWorks_bg_desc {
  color: var(--whiteColor);
  font-family: var(--sansSarif);
  font-size: var(--fontSize15);
  font-weight: 500;
  margin-top: 10px;
}

/* @media screen and (max-width: 1200px) {
  .collabsCafeWorks_Card_Cont {
    width: 100%;
  }
  .collabsCafeWorks_Card {
    width: 100%;
  }
} */

@media screen and (max-width: 800px) {
  .collabsCafeWorks_Card_Cont {
    width: 100%;
  }
  .collabsCafeWorks_Card {
    width: 100%;
  }
  .collabsCafe_guide_Card {
    width: 100%;
  }
}
