.chat_container {
  width: 80%;
  display: flex;
  margin: 0% auto;
  margin-top: 20px;
  height: 80vh;
  gap: 20px;
}

.chat_chats_container {
  width: 30%;
  border: 1px solid var(--softBlack);
  border-radius: 10px;
  padding: 3px;
}

.chat_message_container {
  width: 70%;
  /* box-shadow: var(--searchboxShadow); */
  border: 1px solid var(--softBlack);
  border-radius: 10px;
  /* padding: 10px; */
}

.chat_chats_heading {
  text-align: center;
  font-size: var(--fontSize16);
  font-family: var(--monrope);
}

.conversation_container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 20px;
  /* background-color: red; */
  padding: 7px;
  cursor: pointer;

}

.chat-selected {
  /* padding: 10px 10px 10px 10px; */
  background-color: lightgray;
  border-radius: 10px;
}

.conversation_detail {
  width: 80%;
  /* display: flex; */
  /* background-color: red; */
}

.conversation_name {
  font-size: var(--fontSizeSmall);
  font-family: var(--monrope);
}

.conversation_text_date {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 20px; */
}

.conversation_text_date>p {
  font-size: var(--fontSizeSmall);
  font-family: var(--monrope);
}


.ChatBox_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.ChatBox_Header {
  height: 70px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px 0 10px;
  border-bottom: 1px solid black;
}

.ChatBox_message_Section {
  height: 100%;
  overflow-y: scroll;
  z-index: 1;
  padding: 0 10px 0 10px;
}

.ChatBox_send_message_section {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 10px 0 0px;
}


.chatBox_name {
  font-size: var(--fontSize16);
  font-family: var(--monrope);
}


.send_btn {
  background-color: var(--greenColor);
  /* padding: 14px; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}


.no_AvailableChat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--monrope);
  font-size: 12px;
}



.chat_open_container {
  display: none;
}

.chat_open_message_container {
  display: block;
  width: 100%;
}


@media screen and (max-width: 1000px) {
  .chat_container {
    width: 80%;
    /* margin: 20px; */
  }
}

@media screen and (max-width: 1000px) {
  .chat_container {
    width: 90%;
  }

  .chat_chats_container {
    width: 100%;

  }

  .chat_message_container {
    display: none;
  }
}





















.m-0 {
  margin: 0;
}

.mr-1 {
  margin-right: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.mr-2 {
  margin-right: 11px;
}

.w-80 {
  width: 80%;
}

.align-center {
  align-items: center;
}

.px-10 {
  padding: 0 10px;
}

.invisible {
  visibility: hidden;
}

.main_chat {
  width: 80%;
  height: calc(100vh - 85px);
  display: flex;
  margin: 0% auto;
}

.chat_sidebar {
  width: 25%;
  background-color: rgb(248, 248, 248);
  height: calc(100vh - 85px);
}

.user_profile {
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
  align-items: center;
  font-family: var(--monrope);
  cursor: pointer;
}

.user_profile-selected {
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--iconColor);
  margin-top: 5px;
  align-items: center;
  font-family: var(--monrope);
  cursor: pointer;
}

.user_profile:hover {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.user_profile_image {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: var(--boxShadow);
  margin: 0 10px;
}

.user_profile h2,
h4 {
  color: var(--greenColor);
}

.user_profile_edit {
  padding: 0 10px 0 40px;
  text-align: -webkit-right;
}

.nosubmit {
  border-radius: 50px;
  padding: 0;
}

input.nosubmit {
  border: 1px solid white;
  width: 100%;
  padding: 15px 4px 15px 40px;
  box-shadow: var(--boxShadow);
  background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.chat_search {
  margin-top: 30px;
  padding: 0 16px;
}

.w200 {
  width: 200px;
}

.profile_list {
  margin-top: 20px;
  padding: 0 5px;
}

.user_profile_image2 {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: var(--boxShadow);
  margin: 0 10px;
}

.unread_msg {
  padding: 10px;
  background-color: var(--greenColor);
  border-radius: 100px;
  width: 1px;
  height: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unread_msg p {
  margin: 0;
  color: white;
  font-size: 12px;
}

.user_profile_edit h5 {
  margin: 0;
  margin-bottom: 6px;
  color: rgb(168, 168, 168);
  font-size: 12px;

}

.chat_section {
  width: 75%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.user_profile_section {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--monrope);
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}

.flex {
  display: flex;
  gap: 10px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;


}

.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.chat_messages {
  flex-direction: column;
  min-width: 200px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 .5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.chat_msg_detail,
.my_chat_msg_detail {
  margin-bottom: 10px;
}

.my_chat_msg_detail {
  display: flex;
  justify-content: end;

}

.my_chat {
  background-color: #efefef;
  border-radius: 8px 8px 8px 0;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 350px;
  /* justify-content: center; */
  /* align-items: center; */
  font-family: var(--monrope);

}

.other_chat {
  background-color: var(--greenColor);
  color: var(--whiteColor);
  border-radius: 8px 8px 8px 0;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 350px;
  /* justify-content: center; */
  /* align-items: center; */
  font-family: var(--monrope);

}

.my_chat h5,
.other_chat h5,
.chats_borderless>h5 {
  margin: 1px;
  padding: 0px 8px;
}

.chats_borderless {
  background-color: #efefef;
  border-radius: 8px 8px 8px 8px;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  align-items: center;
  max-width: 450px;
  font-family: var(--monrope);
}

.my_chats {
  background-color: var(--greenColor);
  border-radius: 8px 8px 0px 8px;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  align-items: center;
  color: white;
  font-family: var(--monrope);

}

.chat-last-message {
  color: var(--softBlack) !important;
}

.my_chats_borderless {
  background-color: var(--greenColor);
  border-radius: 8px 8px 8px 8px;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  align-items: center;
  color: white;
  max-width: 450px;
  font-family: var(--monrope);
}

.my_chats h5,
.my_chats_borderless>h5 {
  margin: 1px;
  padding: 4px 8px;
}

.user_profile_image3 {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: var(--boxShadow);
  margin: 0 10px;

}

span.tooltip {
  position: absolute;
  width: 100px;
  height: 20px;
  line-height: 20px;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  color: rgb(113, 157, 171);
  background: rgb(255, 255, 255);
  border: 4px solid rgb(255, 255, 255);
  border-radius: 5px;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
}

span.tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
  top: 44px;
  left: 50px;
}

.chatmsg {
  height: 50px;
  width: 100%;
  background-color: #efefef;
  border-radius: 50px;
  box-shadow: var(--boxShadow) 1px 1px 2px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--monrope);
  margin-top: auto;
  margin-bottom: 5px;
}

.voice {
  padding: 15px;
}

.chat_inp {
  height: 30px;
  width: 100%;
  font-size: larger;
  background: transparent;
  max-width: 850px;
  border: none;
  margin-left: 8px;
}

.chat_inp:focus-visible {
  border: none !important;
  outline: none !important;
}

.chat_inp:focus-within,
.chat_inp:active {
  border: none !important;
}



.chatbg {
  width: 100%;
  /* opacity: 0.2; */
}

.chat_arrow {
  display: none;
}

.chat_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat_modal>h2 {
  margin: 0 auto;
  margin-top: 15px;
  color: var(--greenColor);
  font-family: var(--monrope);

}

.modal_image {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  cursor: pointer;
  border: 2px solid var(--greenColor);
  box-shadow: var(--boxShadow);
  padding: 4px;
  margin: 0 auto;
}


.msg-date {
  display: flex;
  justify-content: end;
  font-size: 10px;
}

.msg-date-from-now {
  width: fit-content;
  margin: 5px;
  background-color: var(--greenColor);
}

.chat-on-dates {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px 0px 15px 0px;
  /* background-color: #818078; */

}

.chat-on-dates>div {
  width: fit-content;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: var(--softBlack);
  color: var(--whiteColor);
  font-family: var(--monrope);
  font-size: 12px;
}


.no-Chats {
  display: flex;
  justify-content: center;
  background-color: #818078;
}



@media screen and (max-width: 900px) {
  .chatbg {
    display: none;
  }

  .chat_sidebar {
    width: 100%;
  }

  .chat_arrow {
    display: block;
  }

  .chat_section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .sidebar_false {
    display: none;
  }

  .user_profile {
    justify-content: space-between;

  }
}