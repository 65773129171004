.creator_Container {
  padding: 0 6% 0px 6%;
  margin-top: 70px;
  padding-bottom: 50px;
  /* background-color: blueviolet; */
}

.creator_cont {
  display: flex;
  justify-content: center;
}

.creator_cont_heading {
  text-align: center;
}

.creator_cont_heading > h1 {
  font-size: var(--headingFont);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creator_cont_heading > p {
  color: var(--grayColor);
  font-size: 105%;
  font-weight: 400;
  font-family: var(--sansSarif);
  margin-top: 10px;
}

.creator_Input_Maincontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.creator_Input_container {
   width: 55%;
   border: 1px solid var(--blackColor);
   border-radius: 50px;
    height: 70px;
    box-shadow: 0px 0px 9px -5px rgba(66, 68, 90, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 20px;
    color: var(--blackColor);
    font-size: var(--fontSizeM);
    font-weight: 500;
    font-family: var(--sansSarif);
    /* justify-content: center; */
}

.creator_Input_container > input {
    width: 100%;
    color: var(--grayColor);
    font-size: var(--fontSizeM);
    font-weight: 500;
    font-family: var(--sansSarif);
    border: none;
    outline: none;
    /* margin-top: 10px; */
}

.creator_Input_container > button {
    background-color: var(--greenColor);
    font-size: var(--fontSizeM);
    font-weight: 600;
    font-family: var(--sansSarif);
    padding: 12px;
    color: var(--whiteColor);
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
}

.video_Guide_Container {
    margin-top: 50px;
    height: 400px;
    display: flex;
    justify-content: center;
}

.video_guide > video {
    /* width: 800px; */
    height: 500px;
    border-radius: 15px;
    box-shadow: -1px 2px 10px -3px rgba(66, 68, 90, 1);
}

@media screen and (max-width: 800px) {
  .creator_cont_heading > h1 {
    font-size: var(--headingFontMedium);
  }
  .creator_cont_heading > p {
    font-size: 90%;
  }
  .creator_Input_container {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .creator_cont_heading > h1 {
    font-size: var(--headingFontSmall);
  }
  .creator_cont_heading > p {
    font-size: 70%;
  }
}
