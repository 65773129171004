.stepSeven {
  width: 70%;
  margin: 0% auto;
  margin-top: 25px;
  /* padding-bottom: 30px; */
}

.stepSeven-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profileImage_Container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.profile_Image {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  cursor: pointer;
  /* box-shadow: var(--boxShadow); */
  border: 1px solid gray;
}

.images_Container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
}


.coverImage_Container {
    width: 49%;
    height: 445px;
    border: 1px solid gray;
    
}

.Images_Cont {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 5px;
}
.img-row-sq{
  display: flex;
  justify-content: space-between;
}

.image_One {
    width: 49%;
    height: 220px;
    cursor: pointer;
    border: 1px solid gray;
}
.image1:hover{
  opacity: 0.7;
}
.image_Two {
  border-radius: 0 8px 0 0;
    width: 49%;
    height: 220px;
    cursor: pointer;
    border: 1px solid gray;
}
.image2{
  border-radius: 0 8px 0 0;

}
.image2:hover{
  opacity: 0.7;
}
.image_Three {
    width: 100%;
    height: 218px;
    cursor: pointer;
    border: 1px solid gray;
    border-radius: 0 0 8px 0;
}

.image3:hover{
  opacity: 0.7;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 10px; */
    cursor: pointer;
}

.image3{
  border-radius: 0 0 8px 0;

}
/* .cover_Image_Btn {
    position: relative;
    top: 10px;
} */

.file_area {
    position: relative;
    height: 100%;
    border-radius: 8px;
    transition-duration: 0.3s;
    background-color: #f6f7f8;
    
}
.cover-img-badge {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: var(--whiteColor);
    font-family: var(--sansSarif);
    padding: 5px;
    border-radius: 8px;
    font-weight: 600;
    font-size: var(--fontSizeXSmall);
    cursor: pointer;
}

.img-badge {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--whiteColor);
    font-family: var(--sansSarif);
    padding: 5px;
    border-radius: 8px;
    font-weight: 600;
    font-size: var(--fontSizeXSmall);
}

.continue_container {
    width: 100%;
    margin-top: 30px;
  }
  
  .continueBtn {
    width: 100%;
    height: 45px;
    font-size: var(--fontSizeM);
    font-weight: 500;
    font-family: var(--sansSarif);
    background-image: var(--headingLinear);
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .continueBtn:hover {
    opacity: 0.8;
  }


 

@media screen and (max-width: 940px) {
  .stepSeven {
    width: 90%;
  }
  .coverImage_Container {
    width: 100%;
    height: 220px;
    border-radius: 0% !important;
  }
  .image0{
    border-radius: 0% !important;
  }
  .image3, .image_Three{
    border-radius: 0% !important;
  }
  .Images_Cont {
    width: 100%;
  }
  .continue_container {
    display: flex;
    justify-content: center;
  }
  .image_Three{
    /* display: none; */
  }
  .image_Two{
    border-radius: 0%;

  }
  /* .file_area{
    border-radius: 0%;
  } */
  .image2{
    border-radius: 0%;
  }
}
