.List_header{
    width: 80%;
    margin: 4% auto;
    font-family: var(--sansSarif);
}
.list_title{
    font-size: 210%;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
}

.all_list{
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
    margin-top: 1%;
    flex-wrap: wrap;
}

.no_list {
    color: gray;
    text-align: center;
    margin: 8% auto;
    width: 80%;
}

.heart_list{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 99;
    cursor: pointer;
}