.stepNine {
  width: 30%;
  margin: 0% auto;
  margin-top: 25px;
}

.stepNine-heading {
  font-size: var(--fontSize26);
  font-weight: 600;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.faqsCardContainer {
  height: 300px;
  overflow-x: auto;
}

.faq_card {
    width: 97%;
    height: min-content;
    margin-top: 15px;
    border-radius: 10px;
    border-left: 8px solid #bfc6ca;
    box-shadow: var(--boxShadow)
    
}

.faq_content {
  padding: 15px;
}

.faq_HeadingContainer {
  display: flex;
  justify-content: space-between;
}

.deleteFaqs {

  font-size: var(--fontSize26);
    cursor: pointer;
    transform: rotateZ(45deg);
    font-family: var(--sansSarif);
}

.faq_heading {
  font-size: var(--fontSize18);
  font-weight: 600;
  font-family: var(--sansSarif);
  
}

.faq_Ques_Input {
  width: 98%;
  height: 40px;
  border: 1px solid var(--borderColorTwo);
  font-family: var(--sansSarif);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  margin-top: 10px;
}

.faq_Ques_Input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}

.faq_Ques_description {
  width: 98%;
  border: 1px solid var(--borderColorTwo);
  font-family: var(--sansSarif);
  border-radius: 5px;
  padding-left: 5px;
  outline: none;
  resize: vertical;
  margin-top: 13px;
}
.faq_Ques_description::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--graySmooth);
  font-weight: 500;
  opacity: 1; /* Firefox */
  font-family: var(--sansSarif);
}

.continue_container {
  width: 100%;
  margin-top: 30px;
}

.continueBtn {
  width: 100%;
  height: 45px;
  font-size: var(--fontSizeM);
  font-weight: 500;
  font-family: var(--sansSarif);
  background-image: var(--headingLinear);
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueBtn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .stepNine {
    width: 90%;
  }

  .stepNine-heading {
    margin-left: 15px;
  }

  .continue_container {
    display: flex;
    justify-content: center;
  }
}


.faqsCardContainer::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.faqsCardContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.faqsCardContainer::-webkit-scrollbar-thumb {
  background: var(--greenColor);
}

/* Handle on hover */
.faqsCardContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
