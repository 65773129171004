.categories {
  padding: 0 6% 0px 6%;
  /* margin-top: 70px; */
  margin-top: 40px;
  /* margin-bottom: 20px; */
}

.categories_heading {
  font-size: var(--headingFontSmall);
  font-family: var(--sansSarif);
  color: var(--greenColor);
  font-weight: 600;
}

.categories_Card_Cont {
  width: 50%;
}

.categories_Card_Container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* flex-wrap: wrap; */
  align-content: stretch;
}

.categories_Card_Container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categories_Card_Container {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
margin-top: 5px;
}

.categories_Card {
  width: 100%;
  height: 250px;
 
  /* border-radius: 10px; */

  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
}

.categories_Card_Bg {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
}

.categories_bg_text {
  padding: 10px;
}

.categories_bg_text_Name {
  color: var(--whiteColor);
  font-family: var(--sansSarif);
  font-size: 18px;
  font-weight: 600;
}
.categories_Card_Cont{
width: 100%;
}

@media screen and (max-width: 1200px) {
  .categories_Card_Container {
    overflow-x: auto;
    /* overflow: hidden; */
    gap: 10px;
  }
  .categories_Card_Container::-webkit-scrollbar {
    width: 0px;
  }
  .categories_Card {
    /* width: 200px; */
    height: 170px;
  }
  .categories_Card_Cont {
    /* width: 200px; */
  }
  .categories .slick-slide {
    padding: 0 36px;
  }
}

@media screen and (max-width: 1086px){
  .categories .slick-slide {
    padding: 0 44px;
  }
}

@media screen and (max-width: 800px) {
  .categories_Card {
    /* width: 200px;
    height: 170px; */
  }
  .categories_Card_Cont {
    width: 200px;
  }
  .slick-arrow{
    display: none;
  }
  .categories .slick-slide {
    padding: 0 66px;
  }
}
.categories_Card_Container{
  overflow: scroll;
}
.categories_Card_Cont {
  width: 24%;
  min-width: 280px;
}

@media screen and (max-width: 550px) {
  .influencers_listing-row {
    margin-top: 5%;
  }
  .influencers_profile-listing-img-holder {
    height: 180px;
}
.categories_Card {
    width: 200px;
    height: 170px;
}
.categories_Card_Cont {
  width: 24%;
  min-width: 200px;
}
.categories .slick-slide {
  padding: 0 12px;
}
}

@media screen and (max-width: 360px) {
  .categories .slick-slide {
    padding: 0 24px;
  }
}