.brandSignup {
    /* width: 30%; */

    /* margin-top: 25px; */
    margin: 0% auto;
    height: calc(100vh - 185px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .brandSignup-heading {
    font-size: var(--fontSize26);
    font-weight: 600;
    font-family: var(--sansSarif);
    background-image: var(--headingLinear);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  .fromWhereYouHeard{
    width: 100%;
    height: 45px;
    border: 2px solid var(--borderColorTwo);
    border-radius: 5px;
    padding-left: 5px;
    outline: none;
    margin-top: 15px;
  }


  @media screen and (max-width: 800px) {
    .brandSignup {
      width: 90%;
    }
  
  }
  