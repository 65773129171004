.mobileNav_Container {
  display: none;
}

.mobileNav {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  background-color: var(--whiteColor);
  border: 1px solid var(--borderColor);
  height: 60px;
  cursor: pointer;
}

.searchIcon {
  font-size: 20px !important;
  color: var(--iconColor);
}
.mobileNavs {
  text-align: center;
}

.mobileNavs > a {
  font-size: var(--fontSize10);
  color: var(--grayColor);
  font-weight: 600;
  margin-top: 8px;
  font-family: var(--monrope);
  text-decoration: none;
  transition-duration: 0.3s;
}

@media screen and (max-width: 800px) {
  .mobileNav_Container {
    display: block;
  }
}
