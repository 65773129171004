.explore_influencer {
    display: block;
    text-align: center;
    font-weight: 600;
    border: none;
    font-size: 95%;
    font-family: inherit;
    border-radius: 8px;
    box-shadow: var(--grayColor);
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.3s;
    position: relative;
    background-color: var(--greenColor);
    padding: 16px;
    width: 200px;
    margin: 0 auto;
    color: white;
}


.order_detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.order_detail>div:first-child {
    width: 49%;
    /* flex: 1; */
    border-right: 1px solid var(--graySmooth);
    /* background-color: red; */
}

.order_detail>div:last-child {
    width: 49%;
    /* flex: 1; */
    /* background-color: green; */
}


.order_detail_field_name {
    font-size: var(--fontSizeSmall);
    font-weight: 500;
    font-family: var(--sansSarif);
    color: var(--softBlack);
    margin-top: 7px;
}

.order_detail_field_detail {
    font-size: var(--fontSizeSmall);
    font-weight: 500;
    font-family: var(--sansSarif);
    color: var(--grayColor);

}




@media  screen and (max-width: 800px){
    
.order_detail>div:first-child {
    width: 100%;
    border: none;
    /* flex: 1; */
    /* border-right: 1px solid var(--graySmooth); */
    /* background-color: red; */
}

.order_detail>div:last-child {
    width: 100%;
    /* flex: 1; */
    /* background-color: green; */
}
}

